<div class="page-title-area item-bg3">
    <div class="container">
        <div class="page-title-content">
            <h2>Media</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>media</li>
            </ul>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</div>

<!-- End Page Title Area -->

<!-- Start Blog Details Area -->
<!--<section class="widget widget_volmint_posts_thumb">
    <h3 class="widget-title">Popular Posts</h3>

    <article class="item">
        <a routerLink="/blog-details" class="thumb">
            <span class="fullimage cover bg1" role="img"></span>
        </a>
        <div class="info">
            <time datetime="2019-06-30">June 10, 2019</time>
            <h4 class="title usmall"><a routerLink="/blog-details">Making Peace With The Feast Or Famine Of Freelancing</a></h4>
        </div>
        <div class="clear"></div>
    </article>

    <article class="item">
        <a routerLink="/blog-details" class="thumb">
            <span class="fullimage cover bg2" role="img"></span>
        </a>
        <div class="info">
            <time datetime="2019-06-30">June 21, 2019</time>
            <h4 class="title usmall"><a routerLink="/blog-details">I Used The Web For A Day On A 50 MB Budget</a></h4>
        </div>
        <div class="clear"></div>
    </article>

    <article class="item">
        <a routerLink="/blog-details" class="thumb">
            <span class="fullimage cover bg3" role="img"></span>
        </a>
        <div class="info">
            <time datetime="2019-06-30">June 30, 2019</time>
            <h4 class="title usmall"><a routerLink="/blog-details">How To Create A Responsive Popup Gallery?</a></h4>
        </div>
        <div class="clear"></div>
    </article>
</section>
<section class="blog-details-area ptb-110" style="background-color: #f8f8f8">
    <div class="container" style="box-shadow: 1px 1px 1px 1px; background-color: white;">
        <div>
            <div class="row">
                <div class="col-sm-6 d-lg-none">
                    <h2>Newsroom</h2>
                    <img class="card-img" src="assets/img/blog/Educational-and-Health-care-product-services.png"
                         alt="Card image"/>
                </div>
                <div class="col-sm-6 d-none d-lg-block" style="width: 147px;height: 138px;">
                    <h2>Newsroom</h2>
                    <img class="card-img" src="assets/img/blog/Educational-and-Health-care-product-services.png"
                         alt="Card image"/>
                </div>
                <div class="col-sm-6" style="padding-left: 250px;">
                    <div class="card-body-right">
                        <h4 class="card-title">John Doe</h4>
                        <p class="card-text">Some example text some example text. John Doe is an architect and
                            engineer Some example text some example text. John Doe is an architect and
                            engineer Some example text some example text. John Doe is an architect and
                            engineer Some example text some example text. John Doe is an architect and
                            engineer Some example text some example text. John Doe is an architect and
                            engineer Some example text some example text. John Doe is an architect and
                            engineer Some example text some example text. John Doe is an architect and
                            engineer Some example text some example text. John Doe is an architect and
                            engineer engineer Some example text some example text. John Doe is an architect and
                            engineer Some example text some example text. John Doe is an architect and
                            engineer</p>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div>
            <div class="row">
                <div class="col-sm-6 d-lg-none">
                    <h2>Newsroom</h2>
                    <img class="card-img" src="assets/img/blog/Educational-and-Health-care-product-services.png"
                         alt="Card image"/>
                </div>
                <div class="col-sm-6 d-none d-lg-block" style="width: 147px;height: 138px;">
                    <h2>Newsroom</h2>
                    <img class="card-img" src="assets/img/blog/Educational-and-Health-care-product-services.png"
                         alt="Card image"/>
                </div>
                <div class="col-sm-6">
                    <div class="card-body-right">
                        <h4 class="card-title">John Doe</h4>
                        <p class="card-text">Some example text some example text. John Doe is an architect and
                            engineer Some example text some example text. John Doe is an architect and
                            engineer Some example text some example text. John Doe is an architect and
                            engineer Some example text some example text. John Doe is an architect and
                            engineer Some example text some example text. John Doe is an architect and
                            engineer Some example text some example text. John Doe is an architect and
                            engineer Some example text some example text. John Doe is an architect and
                            engineer Some example text some example text. John Doe is an architect and
                            engineer
                            engineer Some example text some example text. John Doe is an architect and
                            engineer Some example text some example text. John Doe is an architect and
                            engineer</p>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div>
            <div class="row">
                <div class="col-sm-6 d-lg-none">
                    <h2>Newsroom</h2>
                    <img class="card-img" src="assets/img/blog/Educational-and-Health-care-product-services.png"
                         alt="Card image"/>
                </div>
                <div class="col-sm-6 d-none d-lg-block" style="width: 147px;height: 138px;">
                    <h2>Newsroom</h2>
                    <img class="card-img" src="assets/img/blog/Educational-and-Health-care-product-services.png"
                         alt="Card image"/>
                </div>
                <div class="col-sm-6">
                    <div class="card-body-right">
                        <h4 class="card-title">John Doe</h4>
                        <p class="card-text">Some example text some example text. John Doe is an architect and
                            engineer Some example text some example text. John Doe is an architect and
                            engineer Some example text some example text. John Doe is an architect and
                            engineer Some example text some example text. John Doe is an architect and
                            engineer Some example text some example text. John Doe is an architect and
                            engineer Some example text some example text. John Doe is an architect and
                            engineer Some example text some example text. John Doe is an architect and
                            engineer Some example text some example text. John Doe is an architect and
                            engineer
                            engineer Some example text some example text. John Doe is an architect and
                            engineer Some example text some example text. John Doe is an architect and
                            engineer</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>-->
<!-- End Blog Details Area -->
<section style="background-color: #f8f8f8">
    <div class="half-half-image-text bg-white">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-6">
                    <div class="img"
                         style="background: url('https://res.cloudinary.com/highereducation/images/f_auto,q_auto/v1659633669/BestColleges.com/BC_What-Counts-as-a-Tech-Job_26718aa25d/BC_What-Counts-as-a-Tech-Job_26718aa25d.jpg?_i=AA')no-repeat center;background-size:cover;"></div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="row">
                        <div class="col-12">
                            <h1>Our Mission</h1>
                        </div>
                    </div>
                    <div class="content">
                        <p>At Fluid Automotive, our purpose is to make automotive parts easily accessible for everyone.
                            Working with our partner brands, we aim to retail the highest quality parts, whilst
                            maintaining
                            a high level of customer satisfaction.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="half-half-image-text">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-6">
                    <div class="img"
                         style="background: url('https://etimg.etb2bimg.com/photo/64274594.cms')no-repeat center;background-size:cover;"></div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="row">
                        <div class="col-12">
                            <h1>Our Mission</h1>
                        </div>
                    </div>
                    <div class="content">
                        <p>At Fluid Automotive, our purpose is to make automotive parts easily accessible for everyone.
                            Working with our partner brands, we aim to retail the highest quality parts, whilst
                            maintaining
                            a high level of customer satisfaction.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="half-half-image-text bg-white">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-6">
                    <div class="img"
                         style="background: url('https://assets.entrepreneur.com/content/3x2/2000/20170120170022-GettyImages-623683923.jpeg')no-repeat center;background-size:cover;"></div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="row">
                        <div class="col-12">
                            <h1>Our Mission</h1>
                        </div>
                    </div>
                    <div class="content">
                        <p>At Fluid Automotive, our purpose is to make automotive parts easily accessible for everyone.
                            Working with our partner brands, we aim to retail the highest quality parts, whilst
                            maintaining
                            a high level of customer satisfaction.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="half-half-image-text">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-6">
                    <div class="img"
                         style="background: url('https://images.pexels.com/photos/89784/bmw-suv-all-terrain-vehicle-fog-89784.jpeg?cs=srgb&dl=automobile-bmw-car-89784.jpg&fm=jpg')no-repeat center;background-size:cover;"></div>
                </div>
                <div class="col-12 col-lg-6">
                    <div class="row">
                        <div class="col-12">
                            <h1>Our Mission</h1>
                        </div>
                    </div>
                    <div class="content">
                        <p>At Fluid Automotive, our purpose is to make automotive parts easily accessible for everyone.
                            Working with our partner brands, we aim to retail the highest quality parts, whilst
                            maintaining
                            a high level of customer satisfaction.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
