    import {Component, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CareerService} from '../../../shared/services/career.service';
import {AlertService} from '../../../shared/services/alert.service';
import {FileService} from '../../../shared/services/file.service';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
    selector: 'app-career',
    templateUrl: './career.component.html',
    styleUrls: ['./career.component.scss']
})
export class CareerComponent implements OnInit {
    isHovered = false;
    fileToUpload: File | null = null;
    careerForm: FormGroup;
    issueStatuses: any = [];
    submitted: any = false;
    submitting: any = false;
    fileUploadResumeDoc: any;
    onFileChanged: any;
    supportTickets: any;
    modalType: any;
    jobOpenings:any;
    file_value: File | null = null;
    data: any;
    selectedJob: any;
    constructor(public modalService: NgbModal,
                public fb: FormBuilder,
                private careerService: CareerService,
                private alertService: AlertService,
                private fileService: FileService,
                public router: Router,
                private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.refresh();
        this.getCareerData();
    }

    refresh() {
        this.careerForm = this.fb.group({
            'name': ['', Validators.required],
            'email': ['', Validators.required],
            'contact': ['', Validators.required],
            'experience': ['', Validators.required],
            'applied_for':['', Validators.required]
        });
    }

    getCareerData(){
        this.careerService.getCareerDataJson().subscribe(data => {
            this.jobOpenings = data.data;
            console.log(this.jobOpenings);
        });
    }
    openApplyModal(content,item) {
        this.modalType = 'SUBMIT_FORM';
        this.data = item;
        this.modalService.open(content, {size: 'lg'});
    }

    openSuccessModal(content) {
        this.modalType = 'SUCCESS';
        this.modalService.open(content, {size: 'md'});
    }

    goto(url) {
        this.router.navigateByUrl(url);
    }

    goTo(id: number | null): void {
        if (id !== null) {
            this.router.navigate(['/careers/careers-details/'], { queryParams: { id: id } });
        }
    }
    submit() {
        this.submitted = true;
        if (!this.careerForm.valid) {
            console.log('123', this.careerForm.value);
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.careerForm && this.careerForm.getRawValue() && this.careerForm.getRawValue().name) ? this.careerForm.getRawValue().name : '',
                'email': (this.careerForm && this.careerForm.getRawValue() && this.careerForm.getRawValue().email) ? this.careerForm.getRawValue().email : '',
                'contact': (this.careerForm && this.careerForm.getRawValue() && this.careerForm.getRawValue().contact) ? this.careerForm.getRawValue().contact : '',
                'experience': (this.careerForm && this.careerForm.getRawValue() && this.careerForm.getRawValue().experience) ? this.careerForm.getRawValue().experience : '',
                'file_id': (this.fileUploadResumeDoc && this.fileUploadResumeDoc.id) ? this.fileUploadResumeDoc.id : '',
                'applied_for': (this.data && this.data.title) ? this.data.title : '',
            };
            console.log(params);
            this.careerService.create(params).subscribe(data => {
                console.log("params",params);
                this.submitted = false;
                this.careerForm.patchValue({
                    'name': '',
                    'email': '',
                    'contact': '',
                    'experience': ''
                });
                this.openSuccessModal(data);
                this.fileUploadResumeDoc = undefined;
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].email[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].contact[0]);
                        return;
                    }
                    if (error['error']['errors'].govt_photo_id_no && error['error']['errors'].name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name[0]);
                        return;
                    }
                }
            });
        }
    }

    onFileChange(e, type) {
        console.log('event', e);
        console.log('type' , type);
        this.onFileChanged = e;
        console.log('this.onFileChanged' , this.onFileChanged);
        this.fileService.fileUpload({'type': type, 'fileType': 'NORMAL'}, this.onFileChanged).subscribe(data => {
            console.log('dtaatta' , data);
            if (type === 'VOLMINT_RESUME_DOC') {
                this.fileUploadResumeDoc = data['data'];
                console.log('fileUploadResumeDoc', this.fileUploadResumeDoc);
            }
            this.alertService.showSuccess({title: 'Success', message: 'Uploaded Successfully'});
        }, error => {
            this.alertService.showErrors('Something went wrong, please try again');
        });
    }
/*    goto(data: any= {}) {
        const url = 'careers/careers-details/';
        this.router.navigate([url], { queryParams: { id: data.unique_id}});
    }*/
}
