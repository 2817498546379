import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbModal, NgbPaginationConfig} from '@ng-bootstrap/ng-bootstrap';
import {AlertService} from '../../../shared/services/alert.service';
import {FileService} from '../../../shared/services/file.service';
import {StorageService} from '../../../shared/services/storage.service';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {IdCardService} from '../../../shared/services/id-Card.service';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
    fileToUpload: File | null = null;
    password_icon: boolean = false;
    addProfileForm: FormGroup;
    imageChangedEvent: any;
    croppedImage: any;
    croppedImageEvent: any;
    profileImageUploadArea: any = 'assets/images/image-backgrounds/200-200.png';
    profileImage: any;
    contactForm: FormGroup;
    issueStatuses: any = [];
    submitted: any = false;
    submitting: any = false;
    unique_id: any;
    idCard: any = {};
    file = [];

    constructor(config: NgbPaginationConfig,
                private modalService: NgbModal,
                private alertService: AlertService,
                private fileService: FileService,
                private idCardService: IdCardService,
                private activatedRoute: ActivatedRoute,
                private fb: FormBuilder) {
        config.size = 'sm';
        config.boundaryLinks = true;
        this.unique_id = this.activatedRoute.snapshot.params['unique_id'];
    }

    modalType: any;
    user: any;

    ngOnInit(): void {

        this.user = StorageService.getItem('self');
        this.refresh();
    }

    refresh() {
        this.get();
    }

    update() {
        this.alertService.showSuccess({title: 'Success', message: 'Profile updated successfully'});
    }

    openLg(content) {
        this.modalType = 'PASSWORD';
        this.modalService.open(content, {size: 'lg'});
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        this.croppedImageEvent = event;
    }

    imageLoaded() {
        // show cropper
    }

    cropperReady() {
        // cropper ready
    }

    loadImageFailed() {
        // show message
    }

    onImageChange(e) {
        this.imageChangedEvent = e;
    }

    openProfileModel(content) {
        this.modalType = 'PROFILE_IMAGE';
        this.modalService.open(content);
    }

    saveProfileImage() {
        const params = {
            'type': 'PROFILE_IMAGE',
            'user_id': this.user.user_id,
            'fileType': 'BASE64'
        };
        this.fileService.fileUpload(params, this.croppedImage).subscribe(data => {
            if (data) {
                this.modalService.dismissAll('');
                this.imageChangedEvent = undefined;
                this.updateProfileImage({'user_id': this.user.user_id, 'profile_image': data['data'].id});
            }
        });
    }

    updateProfileImage(data) {
        /* this.profileService.updateProfile(this.user.user_id, data).subscribe(data => {
             this.getDetails();
         });*/
    }

    get(params: any = {}) {
        this.idCard = [];
        this.idCardService.detail(this.unique_id, params).subscribe(data => {
            if (data) {
                this.idCard = data;
            }
        });
    }


}
