import {Component, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ContactUsService} from "../../../shared/services/contact-us.service";
import {AlertService} from "../../../shared/services/alert.service";

@Component({
    selector: 'app-cgms',
    templateUrl: './cgms.component.html',
    styleUrls: ['./cgms.component.scss']
})
export class CgmsComponent implements OnInit {
    @ViewChild('contactFormModal') contactFormModal!: TemplateRef<any>;
    @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
    successMessage: string;
    isModalOpen = false;
    contactForm: FormGroup;
    submitted: any = false;
    submitting: any = false;
    powerfullToUse = [
        {text: 'Communication'},
        {text: 'Centralized Information'},
        {text: 'Task Automation'},
        {text: 'Collaboration'},
        {text: 'Optimization'},
        {text: 'Security'},
    ];
    modules = [
        {text: 'Admissions Management'},
        {text: 'Attendance Management'},
        {text: 'Fees Management'},
        {text: 'Exams Management'},
        {text: 'Library Management'},
        {text: 'Payroll Management'},
        {text: 'Organized Department'},
        {text: 'Record Management'},
    ];
    features = [
        {icon: 'assets/img/landing-new/cgms/membership.png', text: 'Membership'},
        {icon: 'assets/img/landing-new/cgms/conveyance.png', text: 'Conveyance'},
        {icon: 'assets/img/landing-new/cgms/document.png', text: 'Documentation'},
        {icon: 'assets/img/landing-new/cgms/finance.png', text: 'Finance'},
        {icon: 'assets/img/landing-new/cgms/permission.png', text: 'User Permissions'},
        {icon: 'assets/img/landing-new/cgms/integration.png', text: 'Integration'},
        {icon: 'assets/img/landing-new/cgms/intuitive.png', text: 'Intuitive'},
        {icon: 'assets/img/landing-new/cgms/promotions.png', text: 'Promotions'}

    ];
    whyChoose = [
        {
            header: 'Highly Advanced Web-Mobile Platforms',
            text: 'Volmint College Group Management System offers advanced features on web and mobile platforms, providing a seamless user experience and flexibility in administration for all users and stakeholders.'
        },
        {
            header: 'Role-based Login Access for Data Security',
            text: 'CGMS prioritizes data security through a role-based login system, ensuring confidentiality, integrity, and regulated access to sensitive information, protecting it from unauthorized access.'
        },
        {
            header: 'Monitoring & Accurate Information Analysis',
            text: 'CGMS enhances real-time data analysis, enabling administrators to make informed decisions, and improving operational efficiency and strategic planning across college groups.'
        },
        {
            header: 'Complete Document Management and Generation',
            text: 'Volmint’s CGMS streamlines administrative tasks, minimizes paperwork, and promotes efficiency by efficiently managing documents and ensuring orderly storage and retrieval of crucial records.'
        },
        {
            header: 'On-time Notifications through SMS, WhatsApp, and Mail',
            text: 'Volmint\'s GCM ensures efficient communication by delivering notifications via SMS, WhatsApp, and email, ensuring all groups are promptly informed about important updates and events in real time.'
        },
        {
            header: '24*7 Onsite & Online Service Support',
            text: 'Our college group management system offers 24/7 service support, addressing technical issues promptly, and ensuring system reliability, functionality, uninterrupted operations, and user satisfaction.'
        },
    ];
    offerings = [
        {
            text: 'Accounting Module'
        },
        {
            text: 'Academic Team Management'
        },
        {
            text: 'Group Collaboration Software'
        },
        {
            text: 'Online Examination Platform'
        },
        {
            text: 'Mobile Application'
        },
    ];

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private contactService: ContactUsService,
        private alertService: AlertService
    ) {
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh() {
        this.contactForm = this.fb.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            contact: [''],
            subject: ['', Validators.required],
            message: ['', Validators.required]
        });
    }

    openModal() {
        this.isModalOpen = true;
    }

    closeModal() {
        this.isModalOpen = false;
    }

    handleOverlayClick(event: MouseEvent) {
        const target = event.target as HTMLElement;
        if (!target.closest('.modal-dialog')) {
            this.closeModal();
        }
    }

    submit() {
        this.submitted = true;
        if (!this.contactForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().name) ? this.contactForm.getRawValue().name : '',
                'email': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().email) ? this.contactForm.getRawValue().email : '',
                'contact': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().contact) ? this.contactForm.getRawValue().contact : '',
                'subject': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().subject) ? this.contactForm.getRawValue().subject : '',
                'message': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().message) ? this.contactForm.getRawValue().message : '',
            };
            this.contactService.create(params).subscribe(data => {
                this.successMessage = 'Your form has been submitted successfully!';
                this.submitted = false;
                this.contactForm.patchValue({
                    'name': '',
                    'email': '',
                    'contact': '',
                    'subject': '',
                    'message': ''
                });
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].email[0]);
                        return;
                    }
                    if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].contact[0]);
                        return;
                    }
                    if (error['error']['errors'].subject && error['error']['errors'].subject.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].subject[0]);
                        return;
                    }
                    if (error['error']['errors'].message && error['error']['errors'].message.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].message[0]);
                        return;
                    }
                }
            });
        }
    }

    playVideo() {
        const video = this.videoElement.nativeElement;
        const thumbnail = document.getElementById('video-thumbnail');
        if (video) {
            video.classList.remove('d-none');
            video.play();
            if (thumbnail) {
                thumbnail.style.display = 'none';
            }
            const playButton = document.querySelector('.play-button');
            if (playButton) {
                playButton.classList.add('d-none');
            }
        }
    }

    goTo(url) {
        this.router.navigateByUrl(url);
    }
}
