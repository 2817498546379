<!-- Start Page Title Area -->
<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <h2>Privacy Policy</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Privacy Policy</li>
            </ul>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start project Details Area -->
<div class="projects-details-area ptb-110">
    <div class="container">
        <div class="projects-details">
            <div class="projects-details-desc">
                <h3 style="text-align: center; color: #1b41b0;">Privacy Policy</h3>
                <p>Welcome to <b> Volmint Technologies </b> available on the link <b>https://volmint.com/</b> .<b>“Website</b>,<b>Us</b>,
                    <b>we</b>” are
                    used to refer to Volmint Technologies, and “<b>You</b>, <b>Users</b> or <b>Visitors</b>” are used to
                    refer to an
                    individual, legal entity, or company that accesses or runs our website.
                </p>
                <p>As one of the top software providers, Volmint Technologies is bound to serve complete satisfaction
                    and security to visitors concerning the use of our website.
                    We are committed to ensuring your
                    privacy and security while you are visiting our website at the best of our practice. Your personal
                    and business information is completely safe and secure at any aspect of our capabilities.</p>
                <ul>
                    <h6><b>Information and details We may Gather</b></h6>
                    <li><p>Generally, we may collect information like email addresses and contact numbers that
                        voluntarily submit to us. However, the information we may gather depends upon how you use our
                        site and the information related to the pages you have visited. It may include the service,
                        links, and information you are looking for and would choose to access.
                    </p>
                    </li>
                    <li><p>We may also collect information about the device(s) access to our portal or website. This
                        includes the type of device Laptop, Desktop, mobile device ID, or IP address along with online
                        identifiers linked with Cookie-ID.
                    </p>
                    </li>
                    <li><p>When you sign-up to follow our social media links and pages, we may save the details to
                        respond to your query as per your requirement. Also, we may save the information about your
                        preference and area of interest.
                    </p>
                    </li>
                </ul>
                <h6><b>The motive to Gather the information</b></h6>
                <p>
                    We require all this information to understand your preferences and needs to provide the best
                    experience and better service on our website. The information we collect can help us to improve our
                    services and to send the marketing communication that helps us to improve day by day. </p>
                <ul style="list-style: none">
                    <li><b>Is your information safe?</b>
                        <p>Being one of the top-notch software providers, we are known for our commitment and trust.
                            Volmint Technologies does not reveal, share, sell or distribute any personal or professional
                            information of our visitors and clients with any third parties.
                        </p>
                    </li>
                    <li><b>Cookie Usage</b>
                        <p> By permitting Cookie, it helps to analyze web traffic or let the visitor know when he/she
                            visits
                            our site. We may use these traffic log cookies to identify which pages are being used.
                            However,
                            it is up to the visitors to allow or decline the cookie.
                        </p>
                    </li>
                    <li><b>Linked Websites</b>
                        <p> We are not liable or responsible for other websites or/ adds or external links available on
                            our
                            website. If the visitors provide any information to third parties or linked websites
                            different
                            rules may apply.
                        </p>
                    </li>
                    <li>
                        <p>Reporting and analytics: Sales teams largely depend on goals set to be accomplished. CRM
                            helps to compare analytics and report on the achievement of goals. This software makes the
                            process hassle-free and convenient. </p>
                    </li>
                    <li>
                        <p>Usability: This software helps you get the information as and when required. Thus, it helps
                            in making decisions quickly and pursuing different accounts conveniently without tinkering
                            with CRM.</p>
                    </li>
                </ul>
                <h4><b>Training</b></h4>
                <ul style="list-style: none">
                    <li>
                        <p>Volmint Technologies Pvt Ltd does not provide any kind of free or paid training, either
                            directly or indirectly. As a company, we do not believe in providing training services to
                            individuals or organizations. It is important to note that if any individual or organization
                            is providing training services on our company's behalf without our explicit consent, we are
                            not responsible for any consequences that may arise as a result of their actions. </p>
                    </li>
                    <li>
                        <p>Our company's policy is to ensure the privacy and security of our customers and their data.
                            We take all necessary measures to protect the confidentiality of our customers' personal and
                            business information. Our policy also prohibits the use of any customer data for training
                            purposes, whether it is free or paid. </p>
                    </li>
                    <li>
                        <p>We respect the privacy of our customers and are committed to protecting their personal
                            information. We do not share any customer data with third-party training providers or any
                            other unauthorized parties. Our privacy policy is designed to ensure that our customers'
                            information is always safe and secure. </p>
                    </li>

                </ul>
                <h4><b>Internship</b></h4>
                <ul style="list-style: none">
                    <li>
                        <p>Volmint Technologies Pvt Ltd provides a stipend internship program to candidates who meet our
                            selection criteria. We do not charge any fees for our internship program, and we believe in
                            providing quality training and learning opportunities to our interns. Our selection process
                            is rigorous and thorough, and we conduct interviews to select the best candidates for the
                            program. Any interested candidate can find information about our internship program and
                            vacancy notifications on our website, specifically the career section of volmint.com.. </p>
                    </li>
                    <li>
                        <p> It is important to note that any individual or organization claiming to offer internships on
                            our company's behalf without our consent or involvement is not authorized to do so, and we
                            are not responsible for any consequences that may arise as a result of their actions.

                        </p>
                    </li>
                    <li>
                        <p>We take privacy and policy seriously at Volmint Technologies Pvt Ltd. We are committed to
                            ensuring the safety and security of our interns' personal and professional information, and
                            we comply with all relevant data protection laws and regulations. Our privacy policy
                            prohibits the use of any intern's data for purposes other than the internship program
                            itself, and we do not share any intern data with third-party providers or unauthorized
                            individuals or organizations. </p>
                    </li>

                </ul>
                <p><b>Note</b>: The above-mentioned privacy policy is subject to change or modification occasionally to
                    meet the standard and requirements. Therefore, visitors are highly appreciated to visit the Privacy
                    Policy section frequently. </p>
                <p>For any queries, questions, or objections related to our Privacy Policy, please let us know.</p>
                <p>Contact :</p>
                <ul>
                    <li><p> 29/2, Vijay Enclave, Dwarka-Palam Road, Delhi-110045
                    </p></li>
                    <li><p> Mobile: +91 9958029883
                    </p></li>
                    <li><p> E-mail: support@volmint.com
                    </p></li>
                </ul>
            </div>
        </div>
    </div>
</div>
<!-- End project Details Area -->
