<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <p class="fs-5 color-white text-center">Blogs</p>
            <p class="fs-1 color-white text-center fw-bold">Explore Our Latest Tech Trends and Innovations</p>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</div>

<div class="container mt-5">
    <div class="row g-4">
        <div class="col-12 col-md-4" *ngFor="let blog of blogs">
            <div class="card card-custom card-image hover-effect" style="cursor: pointer" (click)="goTo('blog-details/'+ blog?.url)"
                 [ngStyle]="{ 'background-image': 'url(' + blog.blog_banner.url + ')' }">
                <div class="card-body">
                    <h5 class="card-title"
                        (click)="goTo('blog-details/'+ blog?.url)">{{ blog.heading }}</h5>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-5 py-5"
     style="background-image: url('/assets/img/landing-new/footer/footer-bg.png');">
    <div class="container">
        <div class="row text-center text-md-start">
            <div class="col-md-6 col-12 fs-1 color-white fw-bold mb-3 mb-md-0">
                Ready to take your Business’ IT to the next level?
            </div>
            <div class="col-md-6 col-12 text-md-end">
                <button class="bg-F5FCFF px-5 py-3 border-0 fs-6 fw-bold hover-effect" (click)="goTo('/contact')">
                    Contact Us <i class="fa fa-arrow-right" aria-hidden="true"></i>
                </button>
            </div>
        </div>
    </div>
</div>

