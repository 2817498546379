<!-- Start Page Title Area -->
<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <h2>Refund and Cancellation Policy</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Refund and Cancellation Policy</li>
            </ul>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</div>
<!-- End Page Title Area -->


<!-- Start project Details Area -->
<div class="projects-details-area ptb-110">
    <div class="container">
        <div class="projects-details">
            <div class="projects-details-desc">
                <h3 style="text-align: center; color: #1b41b0;">Refund & Cancellation Policy</h3>
                <p>We at  <b> Volmint Technologies </b> work hard to produce high-quality software that you will love using in your
                    professional or business life. We value your business and money even though you have a lot of options. We are
                    grateful to be chosen!
                </p>
                <p>The refund policy of Volmint Technologies can vary depending on the company's terms and conditions. This policy
                    enlightens that if we don't live up to your expectations then are liable for a refund/cancellation of service on different
                    levels.</p>
                <ul>
                    <li> <p> <b>Cancellation before work begins: </b> If you request a refund before any work has been initiated on your
                        website, you may be eligible for a full or partial refund, depending on the company's policy. The company
                        may deduct any administrative fees or costs incurred during the initial setup process.
                    </p>
                    </li>
                    <li><p> <b>Cancellation during the development process: </b> If you decide to cancel the website development project
                        after work has started but before completion, the refund policy may vary. Volmint Technologies reserved
                        the right to offer a partial refund/no refund/full refund based on the progress made.
                    </p>
                    </li>
                    <li><p> <b>Unsatisfactory final product: </b> If the completed project does not meet your expectations or the agreed-upon
                        specifications, you may be eligible for a refund. The company may provide a full refund, or they may offer
                        to revise and make necessary changes to address your concerns.
                    </p>
                    </li>
                    <li><p> <b>Buggs or incomplete solution: </b>  If that product is buggy, untested or the issue is not resolved within a time
                        frame as committed. You may raise for a full refund or revision of the product.
                    </p>
                    </li>
                    <li><p> <b>Exclusions and limitations: </b>Refund policies often have certain exclusions and limitations. For example, if
                        you have already approved the final design or provided your explicit acceptance, it may affect your
                        eligibility for a refund. Additionally, the refund policy may not cover any third-party costs, such as domain
                        registration fees or premium plugins/extensions.
                    </p>
                    </li>
                    <li><p> <b>Timeframe for refund requests: </b>Volmint Technologies usually have a specific timeframe within which
                        you must request a refund. This timeframe can vary, but it's typically within 15 days after project initiation
                        or completion.
                    </p>
                    </li>
                </ul>
                <h4><b>A full or partial refund cannot be provided if</b></h4>

                <ul>
                    <li><p>The product works properly
                        </p>
                    </li>
                    <li><p>Having no Buggs and tested well
                    </p>
                    </li>
                    <li><p>Developed as expected
                    </p>
                    </li>
                    <li><p>It is not working for a specific need even after complete support from us
                    </p>
                    </li>
                    <li><p>Fatal errors in activation due to wrong domain or PHP version
                    </p>
                    </li>
                    <li><p>Raised query or issue solved appropriately within a given timeframe
                    </p>
                    </li>
                </ul>
                <p><b>Note:</b> To guarantee that the claims are legitimate and eligible from our end, it is your responsibility to grant us
                    access to the instance in which our product is installed or any alternative methods, such as a common staging/testing
                    site.</p>
                <h4><b>Withdrawal from our Refund Policy</b></h4>
                <ul style="list-style: none">
                    <li>
                        <p>If your account is canceled, suspended, or blocked by <b>Volmint Technologies</b> due to behavior that violates our Terms
                            of Service, including but not limited to phishing, impersonation, fraud, and breaking any applicable laws, there will
                            under no circumstances be a refund (FULL OR PRO-RATED). </p>
                    </li>
                    <li>
                        <p>It is advisable to go through the privacy policy/ refund policy and connect with us to avoid any miscommunication
                            before making a purchase. Since you are given a license to use the digital product and can request a refund while
                            still using it, we have included all the information above to ensure that our customers receive the benefit of a refund
                            while also preventing anyone from abusing the policy.</p>
                    </li>
                    <li>
                        <p>If you ever feel unsatisfied with our service or collaboration, kindly let us know. We will make every effort to find a
                            solution, suggest a workaround, or establish a timeline for a practical fix. If you are dissatisfied, we will gladly
                            downgrade your account and issue you a FULL/PARTIAL REFUND for your purchase.</p>
                    </li>
                    <li>
                        <p>We sincerely hope that you accept and fully abide by our policy, and we will be delighted to address any questions
                            or recommendations you may have about it.</p>
                    </li>

                </ul>
            </div>
        </div>
    </div>
</div>
<!-- End project Details Area -->
