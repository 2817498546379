import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-education-mitra',
  templateUrl: './education-mitra.component.html',
  styleUrls: ['./education-mitra.component.scss']
})
export class EducationMitraComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
