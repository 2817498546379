<!-- Start Page Title Area -->
<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <h2>Education Mitra</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Education Mitra</li>
            </ul>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</div>

<!--Content section-->

<section class="projects-area ptb-110">
    <div class="circle-shape1"><img src="assets/img/projects-shape.png" alt="image"></div>
    <div class="container-fluid" style="padding-left:20px; width: 90% ">
        <div class="row">
            <div class="col-lg-7 col-md-12 col-sm-12">
                <h3 class="lms-title">The Ultimate search engine platform offering a comprehensive database of Open and
                    Distance Learning programs, personalized counseling, and information on UGC-approved Higher
                    Education Institutions for Online/Distance programs.
                </h3>
                <p class="lms-title-content mt-2">An advanced, user-friendly search engine platform offering a vast
                    database of Open and Distance Learning/Online Learning programs, along with free guidance and
                    counseling sessions.</p>
                <p class="lms-title-content">Access comprehensive information on Undergraduate/Postgraduate programs and
                    Higher Education Institutions authorized by UGC to offer Online/Distance education.</p>
                <p class="lms-title-content">Education Mitra facilitates personalized one-to-one live counseling
                    sessions, assisting learners in making informed decisions about their educational journey.</p>
                <p class="lms-title-content">Explore a comprehensive resource hub, empowering individuals with the
                    knowledge and guidance needed for successful online and distance learning pursuits.</p>
            </div>
            <div class="col-5">
                <a routerLink="/contact">
                    <img src="assets/img/icons/new/Education-Mitra-gif.gif" alt="">
                </a>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 mt-3 text-center mt-5">
                <video width="90%" autoplay class="lms-first-video">
                    <source src="./assets/project/education-mitra.mp4" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>
        <section class="features-lms-section ">
            <div class="row">
                <div class="col-12 lms-header text-center ">
                    <h2 class="py-5">Education Mitra Key Features</h2>
                </div>
            </div>

            <div class="row lms-features-area">
                <div class="col-lg-3 col-md-6 col-sm-12">
                    <div class="row">
                        <div class="col my-2 text-center">
                            <div class="card ">
                                <div class="card-body">
                                    <img src="assets/img/icons/online-course.png" alt="" width="30%">
                                    <h6 class="mt-2">Courses</h6>
                                    <p class="card-text">Online learning offerings provided by the website, covering
                                        various subjects, skill development, certifications, and educational content to
                                        support learners' personal and professional growth.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12">
                    <div class="row">
                        <div class="col my-2 text-center">
                            <div class="card ">
                                <div class="card-body">
                                    <img src="assets/img/icons/university.png" alt="" width="30%">
                                    <h6 class="mt-2">Universities</h6>
                                    <p class="card-text">Featured or affiliated higher education institutions offering
                                        diverse academic programs, degrees, courses, and resources accessible to
                                        students and learners through the website's platform.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12">
                    <div class="row">
                        <div class="col my-2 text-center">
                            <div class="card ">
                                <div class="card-body">
                                    <img src="assets/img/icons/blog.png" alt="" width="30%">
                                    <h6 class="mt-2">Latest Blogs</h6>
                                    <p class="card-text">Up-to-date articles, posts, or news published on the website,
                                        covering educational trends, insights, tips, and relevant topics to inform and
                                        engage readers in the education community.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12">
                    <div class="row">
                        <div class="col my-2 text-center">
                            <div class="card">
                                <div class="card-body">
                                    <img src="assets/img/icons/shrug.png" alt="" width="30%">
                                    <h6 class="mt-2">Why Us</h6>
                                    <p class="card-text">A webpage explaining the advantages of choosing the website,
                                        such as high-quality content, experienced instructors, interactive learning
                                        tools, flexible options, and positive student testimonials.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-12">
                    <div class="row">
                        <div class="col my-2 text-center">
                            <div class="card ">
                                <div class="card-body">
                                    <img src="assets/img/icons/video-player.png" alt="" width="30%">
                                    <h6 class="mt-2">Tranding Videos</h6>
                                    <p class="card-text">Popular and widely viewed videos on the website, showcasing
                                        educational content, tutorials, lectures, demonstrations, and engaging
                                        multimedia resources for learners.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12">
                    <div class="row">
                        <div class="col my-2 text-center">
                            <div class="card ">
                                <div class="card-body">
                                    <img src="assets/img/icons/rating.png" alt="" width="30%">
                                    <h6 class="mt-2">Testimonials</h6>
                                    <p class="card-text">Reviews and feedback provided by students, learners, or users,
                                        highlighting their positive experiences, outcomes, and satisfaction with the
                                        website's offerings, courses, or services.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12">
                    <div class="row">
                        <div class="col my-2 text-center">
                            <div class="card ">
                                <div class="card-body">
                                    <img src="assets/img/icons/landing-page.png" alt="" width="30%">
                                    <h6 class="mt-2">Other pages</h6>
                                    <p class="card-text">Supplementary webpages that provide information on topics like
                                        the team, mission, vision, blog archives, student testimonials, alumni network,
                                        scholarship opportunities, and research publications.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12">
                    <div class="row">
                        <div class="col my-2 text-center">
                            <div class="card">
                                <div class="card-body">
                                    <img src="assets/img/icons/illustration.png" alt="" width="30%">
                                    <h6 class="mt-2">customized Design</h6>
                                    <p class="card-text">Tailored visual and user interface elements, including color
                                        schemes, layouts, fonts, graphics, and branding, reflecting the unique identity
                                        and aesthetics of the educational institution or platform.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </section>
    </div>
    <div class="dot-shape1"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.svg" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.svg" alt="image"></div>
</section>
