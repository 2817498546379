import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-apply-now',
  templateUrl: './apply-now.component.html',
  styleUrls: ['./apply-now.component.scss']
})
export class ApplyNowComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
