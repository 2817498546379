import {Component, OnInit} from '@angular/core';
import {TeamService} from '../../../shared/services/team.service';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-team',
    templateUrl: './team.component.html',
    styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {
    teamMember: any;
    itMember: any;
    dmMember: any;
    gdMember: any;
    cwMember: any;
    constructor(private teamData: TeamService, public activatedRoute: ActivatedRoute) {
    }

    ngOnInit() {
        this.get();
    }

    get(params: any = {}) {
        this.teamMember = [];
        this.itMember = [];
        this.dmMember = [];
        this.gdMember = [];
        this.cwMember = [];
        this.teamData.get(params).subscribe(data => {
            if (data) {
                this.teamMember = data;
                (Object.keys(this.teamMember) as (keyof typeof this.teamMember)[]).forEach((key, index) => {
                    if ((this.teamMember[key].department) === 'IT'){
                        this.itMember.push(this.teamMember[key]);
                        return this.itMember;
                    }
                    else if ((this.teamMember[key].department) === 'DM'){
                        this.dmMember.push(this.teamMember[key]);
                        return this.dmMember;
                    }
                    else if ((this.teamMember[key].department) === 'GD'){
                        this.gdMember.push(this.teamMember[key]);
                        return this.gdMember;
                    }
                });
            }
        });
    }
}
