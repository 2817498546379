import {Component, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AlertService} from "../../../shared/services/alert.service";
import {Router} from "@angular/router";
import {ContactUsService} from "../../../shared/services/contact-us.service";

@Component({
    selector: 'app-lms',
    templateUrl: './lms.component.html',
    styleUrls: ['./lms.component.scss']
})
export class LmsComponent implements OnInit {
    @ViewChild('contactFormModal') contactFormModal!: TemplateRef<any>;
    @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
    isModalOpen = false;
    contactForm: FormGroup;
    submitted: any = false;
    submitting: any = false;
    successMessage: string;
    digital = [
        {
            image: 'assets/img/landing-new/lms/lms8.png',
            text: 'Full Analytics'
        },
        {
            image: 'assets/img/landing-new/lms/lms13.png',
            text: 'Easy Access'
        },
        {
            image: 'assets/img/landing-new/lms/lms9.png',
            text: 'Fully Customized'
        },
        {
            image: 'assets/img/landing-new/lms/lms10.png',
            text: 'Save Time'
        },
        {
            image: 'assets/img/landing-new/lms/lms11.png',
            text: 'Real-Time Data'
        },
        {
            image: 'assets/img/landing-new/lms/lms12.png',
            text: 'Process Automation'
        },
    ];

    modules = [
        {text: 'Admissions Management'},
        {text: 'Attendance Management'},
        {text: 'Fees Management'},
        {text: 'Exams Management'},
        {text: 'Library Management'},
        {text: 'Payroll Management'},
        {text: 'Organized Department'},
        {text: 'Record Management'},
    ];

    features = [
        {
            image: 'assets/img/landing-new/lms/lms1.png',
            text: 'Student Engagement'
        },
        {
            image: 'assets/img/landing-new/lms/lms2.png',
            text: 'Virtual Classroom'
        },
        {
            image: 'assets/img/landing-new/lms/lms3.png',
            text: 'Discussion'
        },
        {
            image: 'assets/img/landing-new/lms/lms4.png',
            text: 'Content Creation'
        },
        {
            image: 'assets/img/landing-new/lms/lms5.png',
            text: 'E-Library'
        },
        {
            image: 'assets/img/landing-new/lms/lms6.png',
            text: 'Activities Monitoring'
        },
    ];

    benefits = [
        {icon: 'assets/img/landing-new/ims/benefits/benefits1.png', text: 'Adequate use of time'},
        {icon: 'assets/img/landing-new/ims/benefits/benefits2.png', text: 'Simplified Admission Process'},
        {icon: 'assets/img/landing-new/ims/benefits/benefits3.png', text: 'Ecofriendly System Software'},
        {icon: 'assets/img/landing-new/ims/benefits/benefits4.png', text: 'Integration of All Modules'},
        {icon: 'assets/img/landing-new/ims/benefits/benefits5.png', text: 'Auto Generated Of Documents'},
        {icon: 'assets/img/landing-new/ims/benefits/benefits6.png', text: 'Precise Examinations'},
    ];

    whyChoose = [
        {
            header: 'Customized Forms',
            text: 'You may easily gather pertinent information by creating admission forms that are customized to your institution\'s requirements.'
        },
        {
            header: 'Automated Communication',
            text: 'Keep in touch with applicants at every step of the admissions process by using automated communication tools, such as email and SMS alerts.'
        },
        {
            header: 'Analytics and Report',
            text: 'Data-driven decision-making and continual development are made possible by Volmint\'s analytics and reporting capabilities, which provide insightful information.'
        },
        {
            header: 'Custom Products',
            text: 'Volmint scales to meet your needs, easily accommodating growth and expansion, whether you\'re in charge of admissions for a large organization or a small one.'
        },
        {
            header: '24X7 Support',
            text: 'Our round-the-clock committed support staff is here to help you at every stage, offering rapid assistance and swift resolution of any problems.'
        },
        {
            header: 'Affordable and Customized Product',
            text: 'Volmint, a well-known brand in the software supply industry, provides reasonably priced goods that are adaptable to the needs of the customer.'
        },
    ];

    support = [
        {
            text: '24/7 Availability'
        },
        {
            text: 'Quick Responses'
        },
        {
            text: 'Reliable Help'
        },
        {
            text: 'Expert Assistance'
        },
    ];

    offerings = [
        {
            text: 'Free Lifetime Maintenance'
        },
        {
            text: 'Tailored Assistance '
        },
        {
            text: 'Discounts '
        },
        {
            text: 'Contributions'
        },
        {
            text: 'Training'
        },
        {
            text: 'Counseling'
        },
        {
            text: 'Tools Integration'
        },
        {
            text: 'Free Server'
        },
        {
            text: 'Fast Solution'
        },
        {
            text: 'Updates and Upgrades'
        },
    ];

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private contactService: ContactUsService,
        private alertService: AlertService
    ) {
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh() {
        this.contactForm = this.fb.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            contact: [''],
            subject: ['', Validators.required],
            message: ['', Validators.required]
        });
    }

    openModal() {
        this.isModalOpen = true;
    }

    closeModal() {
        this.isModalOpen = false;
    }

    handleOverlayClick(event: MouseEvent) {
        const target = event.target as HTMLElement;
        if (!target.closest('.modal-dialog')) {
            this.closeModal();
        }
    }

    submit() {
        this.submitted = true;
        if (!this.contactForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().name) ? this.contactForm.getRawValue().name : '',
                'email': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().email) ? this.contactForm.getRawValue().email : '',
                'contact': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().contact) ? this.contactForm.getRawValue().contact : '',
                'subject': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().subject) ? this.contactForm.getRawValue().subject : '',
                'message': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().message) ? this.contactForm.getRawValue().message : '',
            };
            this.contactService.create(params).subscribe(data => {
                this.successMessage = 'Your form has been submitted successfully!';
                this.submitted = false;
                this.contactForm.patchValue({
                    'name': '',
                    'email': '',
                    'contact': '',
                    'subject': '',
                    'message': ''
                });
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].email[0]);
                        return;
                    }
                    if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].contact[0]);
                        return;
                    }
                    if (error['error']['errors'].subject && error['error']['errors'].subject.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].subject[0]);
                        return;
                    }
                    if (error['error']['errors'].message && error['error']['errors'].message.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].message[0]);
                        return;
                    }
                }
            });
        }
    }

    playVideo() {
        const video = this.videoElement.nativeElement;
        const thumbnail = document.getElementById('video-thumbnail');
        if (video) {
            video.classList.remove('d-none');
            video.play();
            if (thumbnail) {
                thumbnail.style.display = 'none';
            }
            const playButton = document.querySelector('.play-button');
            if (playButton) {
                playButton.classList.add('d-none');
            }
        }
    }

    goTo(url) {
        this.router.navigateByUrl(url);
    }

}
