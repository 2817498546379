<section class="contact-area ptb-110"
         style="background-color: rgba(0,0,0,0.1); background-image: url('assets/img/main-banner-bg1.jpg');">
    <div class="container">
        <div class="row">
            <div class="col-12 d-flex flex-row">
                <div class="form_wrapper">
                    <div class="form_container">
                        <div class="title_container">
                            <h2>Apply Now </h2>
                        </div>
                        <div class="row clearfix">
                            <div class="">
                                <form>
                                    <div class="row clearfix">
                                        <div class="col_half">
                                            <div class="input_field"><span><i aria-hidden="true" class="fa fa-user"
                                                                              style=""></i></span>
                                                <input type="text" name="name" placeholder="First Name"/>
                                            </div>
                                        </div>
                                        <div class="col_half">
                                            <div class="input_field"><span><i aria-hidden="true" class="fa fa-user"></i></span>
                                                <input type="text" name="name" placeholder="Last Name" required/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="input_field"><span><i aria-hidden="true"
                                                                      class="fa fa-envelope"></i></span>
                                        <input type="email" name="email" placeholder="Email" required/>
                                    </div>
                                    <div class="input_field"><span><i class="fa-solid fa-address-book"></i></span>
                                        <input type="text" name="name" placeholder="contact" required/>
                                    </div>
                                    <div class="input_field radio_option">
                                        <input type="radio" name="radiogroup1" id="rd1">
                                        <label for="rd1">Male</label>
                                        <input type="radio" name="radiogroup1" id="rd2">
                                        <label for="rd2">Female</label>
                                    </div>
                                    <div class="input_field select_option">
                                        <select>
                                            <option>Frontend Developer</option>
                                            <option>Backend Developer</option>
                                            <option>Full Stack</option>
                                            <option>Digital Marketing</option>
                                            <option>Research Analyst</option>
                                            <option>Academic Counsellors</option>
                                            <option>Digital Promoter</option>
                                            <option>Graphic Designer</option>
                                            <option>Content Writer</option>
                                            <option>Other</option>
                                        </select>
                                        <div class="select_arrow"></div>
                                    </div>
                                    <div class="input_field">
                                        <!--<span>Upload</span>-->
                                        <input type="file" name="Upload"
                                               accept="application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                                    </div>
                                    <div class="input_field checkbox_option">
                                        <input type="checkbox" id="cb1">
                                        <label for="cb1">I agree with terms and conditions</label>
                                    </div>
                                    <input class="button" type="submit" value="Apply Now"/>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

