<div class="page-title-area item-bg3">
    <div class="container">
        <div class="page-title-content">
            <h2>Fun Facts</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Fun Facts</li>
            </ul>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</div>
<div class="ai-pricing-area pt-50 pb-70">
    <div class="container">
        <div class="section-title with-underline-text">
            <!-- <h2>Easy <b>Pricing Plan</b> For Your Specific Needs And Solutions</h2>
             <p>Lorem ipsum dolor sit amet consectetur adipiscing elit. A ac, ut eget pellentesque nulla viverr.</p>-->
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="ai-pricing-card">
                    <ul class="pricing-features">
                        <li><i class='fa-solid fa-check'></i> Drag & Drop Builder</li>
                        <li><i class='fa-solid fa-check'></i> Lead Generation & Sales</li>
                        <li><i class='fa-solid fa-check'></i> Boot & Digital Assistants</li>
                        <li><i class='fa-solid fa-check'></i> Customer Service</li>
                        <li><i class='fa-solid fa-check'></i> Up to 1000 Subscribers</li>
                        <li><i class='fas fa-times'></i> Unlimited Broadcasts</li>
                        <li><i class='fas fa-times'></i> Landing Pages & Web Widgets</li>
                    </ul>
                    <img src="assets/img/woman-smile.png"/>
                    <ul class="pricing-features">
                        <li><i class='fa-solid fa-check'></i> Drag & Drop Builder</li>
                        <li><i class='fa-solid fa-check'></i> Lead Generation & Sales</li>
                        <li><i class='fa-solid fa-check'></i> Boot & Digital Assistants</li>
                        <li><i class='fa-solid fa-check'></i> Customer Service</li>
                        <li><i class='fa-solid fa-check'></i> Up to 1000 Subscribers</li>
                        <li><i class='fas fa-times'></i> Unlimited Broadcasts</li>
                        <li><i class='fas fa-times'></i> Landing Pages & Web Widgets</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="ai-pricing-card">
                    <ul class="pricing-features">
                        <li><i class='fa-solid fa-check'></i> Drag & Drop Builder</li>
                    </ul>
                    <img src="assets/img/woman-smile.png"/>
                    <ul class="pricing-features">
                        <li><i class='fa-solid fa-check'></i> Drag & Drop Builder</li>
                        <li><i class='fa-solid fa-check'></i> Lead Generation & Sales</li>
                        <li><i class='fa-solid fa-check'></i> Boot & Digital Assistants</li>
                        <li><i class='fa-solid fa-check'></i> Customer Service</li>
                        <li><i class='fa-solid fa-check'></i> Up to 1000 Subscribers</li>
                        <li><i class='fas fa-times'></i> Unlimited Broadcasts</li>
                        <li><i class='fas fa-times'></i> Landing Pages & Web Widgets</li>
                        <li><i class='fa-solid fa-check'></i> Drag & Drop Builder</li>
                        <li><i class='fa-solid fa-check'></i> Lead Generation & Sales</li>
                        <li><i class='fa-solid fa-check'></i> Boot & Digital Assistants</li>
                        <li><i class='fa-solid fa-check'></i> Customer Service</li>
                        <li><i class='fa-solid fa-check'></i> Up to 1000 Subscribers</li>
                        <li><i class='fa-solid fa-check'></i> Up to 1000 Subscribers</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="ai-pricing-shape-1">
        <img src="assets/img/home-six/pricing-shape.png" alt="image">
    </div>
    <div class="ai-pricing-shape-2">
        <img src="assets/img/home-six/pricing-shape.png" alt="image">
    </div>
</div>
