import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-university-management-system',
  templateUrl: './university-management-system.component.html',
  styleUrls: ['./university-management-system.component.scss']
})
export class UniversityManagementSystemComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
