<div class="modal fade show d-block" tabindex="-1" aria-labelledby="contactFormModalLabel" *ngIf="isModalOpen"
     aria-hidden="true" style="display: block;">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bg-grey">
                <p class="modal-title fs-5 fw-bolder " id="contactFormModalLabel"><i class="fa fa-address-book primary-blue"></i> Contact Us</p>
                <button type="button" class="btn-close-icon shadow-sm " (click)="closeModal.emit()" aria-label="Close">
                    <i class="fa fa-times fw-bold"></i>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="contactForm" class="border-0 p-4">
                    <div class="mb-3">
                        <label for="name" class="form-label fw-bold">Name</label>
                        <input type="text" id="name" class="form-control rounded-3 py-3 shadow-sm fw-bold" formControlName="name"
                               placeholder="Enter Your Name" required>
                        <small *ngIf="submitted && contactForm.get('name').hasError('required')"
                               class="text-danger">
                            Field is required.
                        </small>
                    </div>
                    <div class="mb-3">
                        <label for="email" class="form-label fw-bold">Email</label>
                        <input type="email" id="email" class="form-control rounded-3 py-3 shadow-sm fw-bold"
                               placeholder="Enter Your Email" formControlName="email" required>
                        <small *ngIf="submitted && contactForm.get('email').hasError('required')"
                               class="text-danger">
                            Field is required.
                        </small>
                    </div>
                    <div class="mb-3">
                        <label for="phone" class="form-label fw-bold">Phone Number</label>
                        <input type="tel" id="phone" class="form-control rounded-3 py-3 shadow-sm fw-bold"
                               placeholder="Enter Your Phone Number" formControlName="contact">
                        <small *ngIf="submitted && contactForm.get('contact').hasError('required')"
                               class="text-danger">
                            Field is required.
                        </small>
                    </div>
                    <div class="mb-3">
                        <label for="subject" class="form-label fw-bold">Subject</label>
                        <input type="text" id="subject" class="form-control rounded-3 py-3 shadow-sm fw-bold" placeholder="Subject"
                               formControlName="subject" required>
                        <small *ngIf="submitted && contactForm.get('subject').hasError('required')"
                               class="text-danger">
                            Field is required.
                        </small>
                    </div>
                    <div class="mb-3">
                        <label for="message" class="form-label fw-bold">Your Message</label>
                        <textarea id="message" class="form-control rounded-3 shadow-sm fw-bold" placeholder="Message"
                                  formControlName="message" rows="4" style="resize: none;"
                                  required></textarea>
                        <small *ngIf="submitted && contactForm.get('message').hasError('required')"
                               class="text-danger">
                            Field is required.
                        </small>
                    </div>
                </form>
            </div>
            <div class="modal-footer d-flex justify-content-between bg-grey">
                <button type="button" class="btn bg-white fs-6 fw-bolder shadow-sm" (click)="closeModal.emit()">Close</button>
                <button type="submit" class="btn bg-color-blue color-white fs-6 fw-bolder shadow-sm" (click)="submit()">Submit</button>
            </div>
        </div>
    </div>
</div>
