import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Router} from "@angular/router";

@Component({
    selector: 'app-vcalling',
    templateUrl: './vcalling.component.html',
    styleUrls: ['./vcalling.component.scss']
})
export class VcallingComponent implements OnInit {
    @ViewChild('contactFormModal') contactFormModal!: TemplateRef<any>;
    isModalOpen = false;
    buttons = [
        {label: 'Capture Leads', key: 'capture'},
        {label: 'Unified Communication', key: 'unified'},
        {label: 'Efficient Management', key: 'efficient'},
        {label: 'Support Team', key: 'support'}
    ];
    design = {
        capture: [
            {
                image: 'assets/img/landing-new/vcalling/capture.avif',
                description: 'Accomplish your monthly targets with the V-Calling quickly. V-Calling allows users to effortlessly gather leads from popular social media platforms, ensuring they never miss out on potential prospects for their social media campaigns.'
            }
        ],
        unified: [
            {
                image: 'assets/img/landing-new/vcalling/uc.jpg',
                description: 'Make yourself more productive to ensure fast conversation. V-Calling allows users to manage calls from any device, simplifying lead interactions and ensuring consistent follow-up without the need for multiple tools.'
            }
        ],
        efficient: [
            {
                image: 'assets/img/landing-new/vcalling/efficient.webp',
                description: 'Monitor lead activity, set reminders, and automate follow-up tasks, ensuring that no opportunity slips through the cracks. This holistic approach to lead management enhances your ability to convert prospects into customers effectively.'
            }
        ],
        support: [
            {
                image: 'assets/img/landing-new/vcalling/support.avif',
                description: 'Work with efficiency, minimize errors, and resolve customer queries rapidly. The solution helps to maximize customer support center availability and response rate with our V-Calling support team system. '
            }
        ],
    };
    currentDesign = this.design.capture;
    switch = [
        {
            image: 'assets/img/landing-new/vcalling/vcalling2.5.webp',
            title: 'VoIP Phone System',
            description: 'Elevate your communication game with our VoIP Phone System, which turns your internet connection into a powerful communication tool.'
        },
        {
            image: 'assets/img/landing-new/vcalling/vcalling2.2.webp',
            title: 'Cloud Contact Center',
            description: 'Transform your customer interactions with our Cloud Contact Center, designed to deliver seamless support and engagement from anywhere.'
        },
        {
            image: 'assets/img/landing-new/vcalling/vcalling2.3.webp',
            title: 'Auto Sales Dialer',
            description: 'Boost your sales productivity with our Auto Sales Dialer, a tool crafted to streamline your calling process, automate and optimize your outreach.'
        },
        {
            image: 'assets/img/landing-new/vcalling/vcalling2.4.png',
            title: 'Conversational AI',
            description: 'Conversational AI, is an advanced system designed to understand and respond to customer queries with human-like interaction.'
        },
    ];

    /*mobile = [
        {
            image: 'assets/img/landing-new/vcalling/vcalling12.webp',
            heading: 'Download Desktop Apps',
            text: 'Download Desktop App'
        },
        {
            image: 'assets/img/landing-new/vcalling/vcalling13.webp',
            heading: 'Download Mobile Apps',
            text: 'TeleCMI for Android'
        },
        {
            image: 'assets/img/landing-new/vcalling/vcalling14.webp',
            heading: 'At Your Desk',
            text: 'Get Your IP Phone'
        },
    ];
*/
    steps = [
        {
            heading: 'Purchase A Number',
            description: 'Select and buy a number that suits your business needs. Whether local or international, we’ve got you covered.'
        },
        {
            heading: 'Register Users',
            description: 'Add users to your system effortlessly. Set up accounts for your team members to ensure smooth communication and collaboration.'
        },
        {
            heading: 'Manage Calls',
            description: 'Start handling calls with ease. V-Calling’s intuitive interface makes call management straightforward, so you can focus on what really matters.'
        },
        {
            heading: 'Monitor and Optimize',
            description: 'Track performance with analytics tools. Gain insights and make data-driven decisions to continuously enhance your communication strategy.'
        },
    ];

    constructor(private router: Router) {
    }

    ngOnInit(): void {
    }

    openModal() {
        this.isModalOpen = true;
    }

    closeModal() {
        this.isModalOpen = false;
    }

    handleOverlayClick(event: MouseEvent) {
        const target = event.target as HTMLElement;
        if (!target.closest('.modal-dialog')) {
            this.closeModal();
        }
    }

    changeDesign(section: string) {
        this.currentDesign = this.design[section];
    }

    goTo(url) {
        this.router.navigateByUrl(url);
    }

}
