import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {AlertService} from "../../../shared/services/alert.service";
import {Router} from "@angular/router";

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
    @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;

    constructor(
        public fb: FormBuilder,
        public alertService: AlertService,
        private router: Router) {
    }

    ngOnInit() {
    }

    goTo(url) {
        this.router.navigateByUrl(url);
    }

    playVideo() {
        const video = this.videoElement.nativeElement;
        const thumbnail = document.getElementById('video-thumbnail');

        if (video) {
            video.classList.remove('d-none');
            video.style.display = 'block';
            video.play();

            if (thumbnail) {
                thumbnail.style.display = 'none';
            }

            const playButton = document.querySelector('.play-button');
            if (playButton) {
                playButton.classList.add('d-none');
            }
        }
    }
}
