import {Component, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {ContactUsService} from "../../../shared/services/contact-us.service";
import {AlertService} from "../../../shared/services/alert.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
    selector: 'app-ums',
    templateUrl: './ums.component.html',
    styleUrls: ['./ums.component.scss']
})
export class UmsComponent implements OnInit {
    @ViewChild('contactFormModal') contactFormModal!: TemplateRef<any>;
    @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
    isModalOpen = false;
    contactForm: FormGroup;
    submitted: any = false;
    submitting: any = false;
    successMessage: string;
    powerfullToUse = [
        {text: 'Centralization'},
        {text: 'Workflows'},
        {text: 'Real-Time Data'},
        {text: 'Secure & Scalable'},
        {text: 'Communication'},
        {text: 'Customization'},
    ];
    features = [
        {icon: 'assets/img/project/admissions.png', text: 'Admissions'},
        {icon: 'assets/img/project/enrollment.png', text: 'Enrollment'},
        {icon: 'assets/img/project/examination.png', text: 'Examinations'},
        {icon: 'assets/img/project/finance.png', text: 'Finances'},
        {icon: 'assets/img/project/Recognitions.png', text: 'Recognitions'},
        {icon: 'assets/img/project/student-tracking.png', text: 'Student Tracking'},
        {icon: 'assets/img/project/notifications.png', text: 'Notifications'},
        {icon: 'assets/img/project/branch-icon.png', text: 'Branch Management'}

    ];
    whyChoose = [
        {
            header: 'Role-Based Access & Permissions',
            text: 'Our UMS ensures robust data security by allowing multiple users to access the system with specific roles and credentials. This role-based access control not only protects sensitive information but also streamlines administrative tasks and optimizes workflow.'
        },
        {
            header: 'User-Friendly Interface',
            text: 'Volmint’s UMS is intuitive and easy to navigate, making university management seamless. With its user-friendly interface, administrators can effortlessly oversee all operations, ensuring smooth and efficient management without any steep learning curve.'
        },
        {
            header: 'Automated Notifications & Alerts',
            text: 'Stay connected and informed with our UMS’s automated notification system. Administrators receive real-time updates via SMS, email, and WhatsApp, keeping them aware of important events and ensuring that no critical information slips through the cracks.'
        },
        {
            header: 'Advanced Security Features',
            text: ' Our UMS prioritizes the security of your university\'s data with advanced, role-based authentication protocols. Both the web and mobile platforms are fortified with cutting-edge security measures, providing a safe and secure environment for all users.'
        },
        {
            header: '24/7 Dedicated Support',
            text: 'Volmint Technologies is committed to supporting your university around the clock. Our dedicated support team is available 24/7, ensuring that any issues are promptly addressed so that your operations remain uninterrupted and run smoothly at all times.'
        },
        {
            header: 'Cost-Effective & Customizable Solutions',
            text: 'We offer highly adaptable and customizable UMS solutions tailored to meet specific needs. Our cost-effective approach ensures that you get the best value for your investment, reducing the need for excessive manpower while increasing overall efficiency.'
        },
    ];

    offerings = [
        {
            text: 'Lifetime Free Maintenance'
        },
        {
            text: 'Personalized Assistance'
        },
        {
            text: 'Lifetime Free Maintenance Updates'
        },
        {
            text: 'Discounts on Extra Modules'
        },
        {
            text: 'Feedback and Feature Request'
        },
        {
            text: 'Third-Party Tool Integration'
        },
        {
            text: 'Consultation and Best Practices'
        },
        {
            text: 'Training Sessions'
        },
        {
            text: 'Resolution within 48 hours'
        },
        {
            text: 'Free Server'
        },
    ];

    constructor(
        private router: Router,
        private contactService: ContactUsService,
        private alertService: AlertService,
        private fb: FormBuilder
    ) {
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh() {
        this.contactForm = this.fb.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            contact: [''],
            subject: ['', Validators.required],
            message: ['', Validators.required]
        });
    }

    openModal() {
        this.isModalOpen = true;
    }

    closeModal() {
        this.isModalOpen = false;
    }

    handleOverlayClick(event: MouseEvent) {
        const target = event.target as HTMLElement;
        if (!target.closest('.modal-dialog')) {
            this.closeModal();
        }
    }

    submit() {
        this.submitted = true;
        if (!this.contactForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().name) ? this.contactForm.getRawValue().name : '',
                'email': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().email) ? this.contactForm.getRawValue().email : '',
                'contact': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().contact) ? this.contactForm.getRawValue().contact : '',
                'subject': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().subject) ? this.contactForm.getRawValue().subject : '',
                'message': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().message) ? this.contactForm.getRawValue().message : '',
            };
            this.contactService.create(params).subscribe(data => {
                this.successMessage = 'Your form has been submitted successfully!';
                this.submitted = false;
                this.contactForm.patchValue({
                    'name': '',
                    'email': '',
                    'contact': '',
                    'subject': '',
                    'message': ''
                });
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].email[0]);
                        return;
                    }
                    if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].contact[0]);
                        return;
                    }
                    if (error['error']['errors'].subject && error['error']['errors'].subject.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].subject[0]);
                        return;
                    }
                    if (error['error']['errors'].message && error['error']['errors'].message.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].message[0]);
                        return;
                    }
                }
            });
        }
    }

    playVideo() {
        const video = this.videoElement.nativeElement;
        const thumbnail = document.getElementById('video-thumbnail');
        if (video) {
            video.classList.remove('d-none');
            video.play();
            if (thumbnail) {
                thumbnail.style.display = 'none';
            }
            const playButton = document.querySelector('.play-button');
            if (playButton) {
                playButton.classList.add('d-none');
            }
        }
    }


    goTo(url) {
        this.router.navigateByUrl(url);
    }

}
