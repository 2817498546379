import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ContactUsService} from "../../../shared/services/contact-us.service";
import {AlertService} from "../../../shared/services/alert.service";
import {Router} from "@angular/router";

@Component({
    selector: 'app-e-clinic',
    templateUrl: './e-clinic.component.html',
    styleUrls: ['./e-clinic.component.scss']
})
export class EClinicComponent implements OnInit {
    @ViewChild('contactFormModal') contactFormModal!: TemplateRef<any>;
    isModalOpen = false;
    currentIndex = 0;
    intervalId: any;
    contactForm: FormGroup;
    submitted: any = false;
    submitting: any = false;
    timeline = [1, 2, 3];
    successMessage: string;
    appointments = [
        {
            image: 'assets/img/landing-new/eclinic/eclinic1.1.png',
            text: 'Quick Scheduling'
        },
        {
            image: 'assets/img/landing-new/eclinic/eclinic1.1.png',
            text: 'Flexible Options'
        },
        {
            image: 'assets/img/landing-new/eclinic/eclinic1.1.png',
            text: 'Immediate Confirmation'
        },
        {
            image: 'assets/img/landing-new/eclinic/eclinic1.2.png',
            text: 'Enhanced Convenience'
        },
    ];

    features = [
        {
            image: 'assets/img/landing-new/eclinic/consultation.png',
            text: 'Consultation Management',
            description: 'Streamline your patient interactions with our intuitive consultation management system. Schedule, track, and manage appointments effortlessly, ensuring efficient time use and improved patient satisfaction.',
        },
        {
            image: 'assets/img/landing-new/eclinic/patient-dashboard.png',
            text: 'Patient Dashboard',
            description: 'An interactive patient detail view that displays every record related to a certain patient. A growth chart for pediatric patients, a diabetic analysis, vital signs, medical histories with allergies, and several invoicing options.',
        },
        {
            image: 'assets/img/landing-new/eclinic/op.png',
            text: 'OP Billing',
            description: 'A E-Clinic Management System simplifies healthcare billing by generating bills for consultations, treatment plans, individual sessions, and outsourced labs, ensuring accuracy, and transparency, and reducing errors and disputes.',
        },
        {
            image: 'assets/img/landing-new/eclinic/docs.png',
            text: 'Documents Management',
            description: 'Keep your clinic’s documentation organized and secure with our robust document management features. Easily store, access, and share patient records and medical documents with a few clicks.',
        },
        {
            image: 'assets/img/landing-new/eclinic/appointment.png',
            text: 'Appointment Management',
            description: 'The software streamlines appointment scheduling in healthcare facilities by providing a centralized calendar, a user-friendly mobile app for patients, and the ability for clinic staff to assist patients, making it more convenient.',
        },
        {
            image: 'assets/img/landing-new/eclinic/inventory.png',
            text: 'Inventory Management',
            description: 'Maintain control over your clinic’s supplies with our comprehensive inventory management tools. Track stock levels, manage orders, and ensure you’re always equipped with essential medical supplies.',
        },
        {
            image: 'assets/img/landing-new/eclinic/staff.png',
            text: 'Staff Management',
            description: 'Efficiently oversee your team with our staff management functionalities. Schedule shifts, monitor performance, and facilitate communication to ensure smooth clinic operations and team coordination.',
        },
        {
            image: 'assets/img/landing-new/eclinic/calendar.png',
            text: 'Calendar Integration',
            description: 'Sync your clinic’s schedules with our calendar integration feature. Seamlessly coordinate appointments, staff shifts, and important events to keep everything organized and on track.',
        },
        {
            image: 'assets/img/landing-new/eclinic/e-prescription.png',
            text: 'e-Prescription',
            description: 'Enhance patient care with our e-prescription feature. Quickly and securely send prescriptions directly to pharmacies, reducing errors and streamlining the medication process for better patient outcomes.',
        },
    ];

    solutions = [
        {
            text: 'Seamless Integration'
        },
        {
            text: 'Enhanced Efficiency'
        },
        {
            text: 'Real-Time Access'
        },
        {
            text: 'Improved Patient Experience'
        },
    ];

    different = [
        {
            text: 'User Friendly',
            description: 'Volmint Technologies provides user-friendly software that is simple to use and accessible, enabling the entire administration of the clinics efficiently and painlessly.',
        },
        {
            text: 'Dedicated 24/7 Support',
            description: 'Our support team is available around the clock to assist with any issues, ensuring uninterrupted service and prompt resolution of any concerns.',
        },
        {
            text: 'Solution-Centric Approach',
            description: 'We focus on providing tailored solutions that address your clinic\'s unique needs, optimizing processes, and enhancing overall efficiency and productivity.',
        },
        {
            text: 'Compatible in All Devices',
            description: 'Our E-Clinic System is fully compatible with all devices, allowing you to manage your clinic seamlessly from any platform, whether desktop, tablet, or mobile.',
        }
    ];

    clients = [
        {
            img: 'assets/img/landing-new/eclinic/client1.png',
            name: 'Rajat Sharma',
            designation: 'UI/UX Designer',
            description: 'Volmint’s E-Clinic System has been a game-changer for us. The ease of scheduling and real-time access have greatly improved our workflow and patient satisfaction.',
            rating: 4
        },
        {
            img: 'assets/img/landing-new/eclinic/client2.png',
            name: 'Monika Khurana',
            designation: 'UI/UX Designer',
            description: 'The user-friendly interface and 24/7 support make this software indispensable. It’s simplified our billing and document management, letting us focus more on patient care.',
            rating: 4
        },
        {
            img: 'assets/img/landing-new/eclinic/client3.png',
            name: 'Sameer Mehta',
            designation: 'UI/UX Designer',
            description: 'With the ability to manage appointments and prescriptions from any device, our clinic runs more smoothly than ever. Highly recommend for its efficiency and ease of use.',
            rating: 4
        },
    ];

    texts = [
        'Login & Enter Basic Details',
        'Define Your Issues',
        'Pay For Consultation Online / Offline'
    ];
    descriptions = [
        'Registered yourself by creating an account following some basic details. Login with your unique credentials and proceed further.',
        'Elaborate about your issues and medical history if necessary to get a proper and relevant consultation along with an expert’s prescription.',
        'Pay the consultation fee online using various payment methods available or you can choose to pay offline also.',
    ];

    images = [
        'assets/img/landing-new/eclinic/eclinic6.1.png',
        'assets/img/landing-new/eclinic/eclinic6.2.png',
        'assets/img/landing-new/eclinic/eclinic6.3.png',
    ];

    constructor(private contactService: ContactUsService,
                public fb: FormBuilder,
                private router: Router,
                public alertService: AlertService) {
    }

    ngOnInit() {
        this.refresh();
    }

    refresh() {
        this.contactForm = this.fb.group({
            'name': ['', Validators.required],
            'email': ['', Validators.required],
            'contact': ['', Validators.required],
            'subject': ['', Validators.required],
            'message': ['', Validators.required]
        });
        this.startAutomaticChange();
    }

    startAutomaticChange() {
        this.intervalId = setInterval(() => {
            this.currentIndex = (this.currentIndex + 1) % this.timeline.length;
        }, 3000);
    }

    openModal() {
        this.isModalOpen = true;
    }

    closeModal() {
        this.isModalOpen = false;
    }

    handleOverlayClick(event: MouseEvent) {
        const target = event.target as HTMLElement;
        if (!target.closest('.modal-dialog')) {
            this.closeModal();
        }
    }

    changeStep(index: number) {
        this.currentIndex = index;
    }

    submit() {
        this.submitted = true;
        if (!this.contactForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().name) ? this.contactForm.getRawValue().name : '',
                'email': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().email) ? this.contactForm.getRawValue().email : '',
                'contact': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().contact) ? this.contactForm.getRawValue().contact : '',
                'subject': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().subject) ? this.contactForm.getRawValue().subject : '',
                'message': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().message) ? this.contactForm.getRawValue().message : '',
            };
            this.contactService.create(params).subscribe(data => {
                this.successMessage = 'Your form has been submitted successfully!';
                this.submitted = false;
                this.contactForm.patchValue({
                    'name': '',
                    'email': '',
                    'contact': '',
                    'subject': '',
                    'message': ''
                });
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].email[0]);
                        return;
                    }
                    if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].contact[0]);
                        return;
                    }
                    if (error['error']['errors'].subject && error['error']['errors'].subject.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].subject[0]);
                        return;
                    }
                    if (error['error']['errors'].message && error['error']['errors'].message.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].message[0]);
                        return;
                    }
                }
            });
        }
    }

    goTo(url) {
        this.router.navigateByUrl(url);
    }

    openCard: number | null = null;

    toggleCard(cardNumber: number): void {
        if (this.openCard === cardNumber) {
            this.openCard = null;
        } else {
            this.openCard = cardNumber;
        }
    }
}
