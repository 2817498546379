import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-company-management-system',
  templateUrl: './company-management-system.component.html',
  styleUrls: ['./company-management-system.component.scss']
})
export class CompanyManagementSystemComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
