import {Component, OnInit} from '@angular/core';
import {IdCardService} from '../../../shared/services/id-Card.service';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-id-card',
    templateUrl: './id-card.component.html',
    styleUrls: ['./id-card.component.scss']
})
export class IdCardComponent implements OnInit {
    idCard: any;
    unique_id: any;

    constructor(private idCardService: IdCardService,
                public activatedRoute: ActivatedRoute) {
        this.unique_id = this.activatedRoute.snapshot.params['unique_id'];
    }

    ngOnInit(): void {
        this.refresh();
    }
    refresh(){
        this.get();
    }

    get(params: any = {}) {
        this.idCard = [];
        this.idCardService.detail(this.unique_id, params).subscribe(data => {
            if (data) {
                this.idCard = data;
                console.log("id card data ", this.idCard);
            }
        });
    }

}
