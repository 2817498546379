<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <p class="fs-5 color-white text-center">Blogs</p>
            <p class="fs-1 color-white text-center fw-bold">Explore Our Latest Tech Trends and Innovations</p>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</div>

<section class="blog-details-area ptb-110">
    <div class="container">
        <div class="row">
            <div class="col-lg-2">
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="blog-details">
                    <div class="article-image">
                        <img src="{{selectedData?.blog_banner.url}}" alt="image">
                    </div>

                    <div class="article-content">

                        <h3>{{ selectedData?.heading }}</h3>

                        <span [innerHTML]="selectedData?.content" style="text-align: justify;"></span>
                    </div>
                </div>
            </div>
            <div class="col-lg-2">
            </div>
        </div>
    </div>
</section>

<div class="container-fluid mt-5 py-5"
     style="background-image: url('/assets/img/landing-new/footer/footer-bg.png');">
    <div class="container">
        <div class="row text-center text-md-start">
            <div class="col-md-6 col-12 fs-1 color-white fw-bold mb-3 mb-md-0">
                Ready to take your Business’s IT to the next level?
            </div>
            <div class="col-md-6 col-12 text-md-end">
                <button class="bg-F5FCFF px-5 py-3 border-0 fs-6 fw-bold hover-effect" (click)="goTo('/contact')">
                    Contact Us <i class="fa fa-arrow-right" aria-hidden="true"></i>
                </button>
            </div>
        </div>
    </div>
</div>
