<!-- Start Page Title Area -->
<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <h2>Admission Management System</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>AMS</li>
            </ul>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</div>

<!--Content section-->

<section class="projects-area ptb-110">
    <div class="circle-shape1"><img src="assets/img/projects-shape.png" alt="image"></div>
    <div class="container-fluid" style="padding-left:20px; width: 90% ">
        <div class="row">
            <div class="col-lg-7 col-md-12 col-sm-12">
                <h3 class="lms-title">Explore our AMS Project, Unveiling advanced features that revolutionize university management and streamline academic operations for comprehensive benefits.
                </h3>
                <p class="lms-title-content mt-2">An admission management system is a software solution that automates
                    and streamlines the entire admission process, from application submission to enrollment, ensuring
                    efficiency, accuracy, and transparency for both applicants and institutions.</p>
                <p class="lms-title-content">It offers features like online application forms, document verification,
                    merit list generation, payment processing, and communication tools to manage the admission cycle
                    seamlessly.</p>
                <p class="lms-title-content">By centralizing and digitizing the admission process, the system eliminates
                    manual paperwork, reduces errors, and provides real-time updates on application status, making the
                    entire process more efficient and convenient.</p>
                <p class="lms-title-content">The admission management system improves the overall experience for both
                    applicants and admission staff, enabling institutions to attract and admit the best-suited
                    candidates while effectively managing the entire admission workflow.</p>
            </div>
            <div class="col-5">
                <a routerLink="/contact">
                    <img src="assets/img/icons/new/RAW.gif" alt="">
                </a>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 mt-4 text-center">
                <video width="90%" autoplay class="lms-first-video">
                    <source src="./assets/project/ams.webm" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>

        <section class="features-lms-section ">
            <div class="row">
                <div class="col-12 lms-header text-center ">
                    <h2 class="py-5">AMS Key Features</h2>
                </div>
            </div>

            <div class="row lms-features-area">
                <div class="col-lg-3 col-md-6 col-sm-12">
                    <div class="row">
                        <div class="col my-2 text-center">
                            <div class="card ">
                                <div class="card-body">
                                    <img src="assets/img/icons/computer.png" alt="" width="30%">
                                    <h6 class="mt-2">Sessions</h6>
                                    <p class="card-text">Predefined time periods for managing admission processes,
                                        including application submission, document verification, merit list generation,
                                        counseling, and final enrollment for admitted students.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12">
                    <div class="row">
                        <div class="col my-2 text-center">
                            <div class="card ">
                                <div class="card-body">
                                    <img src="assets/img/icons/modules.png" alt="" width="30%">
                                    <h6 class="mt-2">Modules/Courses</h6>
                                    <p class="card-text">Academic programs or courses offered by the institution,
                                        including their eligibility criteria, available seats, application requirements,
                                        and selection process for admission consideration.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12">
                    <div class="row">
                        <div class="col my-2 text-center">
                            <div class="card ">
                                <div class="card-body">
                                    <img src="assets/img/icons/apprentice.png" alt="" width="30%">
                                    <h6 class="mt-2">Student Management</h6>
                                    <p class="card-text">The administration and tracking of student information
                                        throughout the admission process, including application status, document
                                        verification, fee payment, and final enrollment.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


                <div class="col-lg-3 col-md-6 col-sm-12">
                    <div class="row">
                        <div class="col my-2 text-center">
                            <div class="card ">
                                <div class="card-body">
                                    <img src="assets/img/icons/structure.png" alt="" width="30%">
                                    <h6 class="mt-2">Levels</h6>
                                    <p class="card-text">Distinct stages or tiers within the admission process, such as
                                        application submission, document verification, merit list generation,
                                        counseling, and final enrollment, each with specific requirements and
                                        procedures.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12">
                    <div class="row">
                        <div class="col my-2 text-center">
                            <div class="card ">
                                <div class="card-body">
                                    <img src="assets/img/icons/brand-engagement.png" alt="" width="30%">
                                    <h6 class="mt-2">Social Leads</h6>
                                    <p class="card-text">Prospective students or applicants who have shown interest or
                                        engaged with the institution through social media platforms, campaigns, or other
                                        digital channels, and are potential candidates for admission.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12">
                    <div class="row">
                        <div class="col my-2 text-center">
                            <div class="card">
                                <div class="card-body">
                                    <img src="assets/img/icons/access-control.png" alt="" width="30%">
                                    <h6 class="mt-2">Access of Roles</h6>
                                    <p class="card-text">Granting specific permissions and privileges to different user
                                        roles such as administrators, admission staff, faculty, and applicants, to
                                        ensure appropriate access to system functionalities and data.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12">
                    <div class="row">
                        <div class="col my-2 text-center">
                            <div class="card">
                                <div class="card-body">
                                    <img src="assets/img/icons/documents.png" alt="" width="30%">
                                    <h6 class="mt-2">Reports</h6>
                                    <p class="card-text">Compiled data and analytics presenting key information on
                                        application status, enrollment figures, demographic breakdowns, and other
                                        admission-related metrics for monitoring and analysis.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </section>
    </div>
    <div class="dot-shape1"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.svg" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.svg" alt="image"></div>
</section>
