import {Component, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ContactUsService} from "../../../shared/services/contact-us.service";
import {AlertService} from "../../../shared/services/alert.service";

@Component({
    selector: 'app-cms',
    templateUrl: './cms.component.html',
    styleUrls: ['./cms.component.scss']
})
export class CmsComponent implements OnInit {
    @ViewChild('contactFormModal') contactFormModal!: TemplateRef<any>;
    @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
    isModalOpen = false;
    contactForm: FormGroup;
    submitted: any = false;
    submitting: any = false;
    successMessage: string;
    powerfullToUse = [
        {text: 'Optimization'},
        {text: 'Communication'},
        {text: 'Integration'},
        {text: 'Information Control'},
        {text: 'Digitalization'},
        {text: 'Centralization'},
    ];
    modules = [
        {text: 'Admissions Management'},
        {text: 'Attendance Management'},
        {text: 'Fees Management'},
        {text: 'Exams Management'},
        {text: 'Library Management'},
        {text: 'Payroll Management'},
        {text: 'Organized Department'},
        {text: 'Record Management'},
    ];
    features = [
        {icon: 'assets/img/project/admissions.png', text: 'Admissions'},
        {icon: 'assets/img/project/enrollment.png', text: 'Enrollment'},
        {icon: 'assets/img/project/examination.png', text: 'Examinations'},
        {icon: 'assets/img/project/finance.png', text: 'Finances'},
        {icon: 'assets/img/project/Recognitions.png', text: 'Recognitions'},
        {icon: 'assets/img/project/student-tracking.png', text: 'Student Tracking'},
        {icon: 'assets/img/project/notifications.png', text: 'Notifications'},
        {icon: 'assets/img/project/branch-icon.png', text: 'Branch Management'}

    ];
    whyChoose = [
        {
            header: 'Advanced Web-Mobile Platforms',
            text: ' Volmint College Management System offers cutting-edge web and mobile platforms. It ensures a seamless user experience with advanced features accessible across devices, enhancing convenience and flexibility in administration.'
        },
        {
            header: 'Role-based Login Access',
            text: 'Volmint CMS prioritizes data security through role-based login access, granting specific permissions to users based on their roles and responsibilities. It ensures confidentiality, integrity, and controlled access to sensitive information and data.'
        },
        {
            header: 'Monitoring & Accurate Information Analysis',
            text: ' The system enables real-time monitoring and precise analysis of data, which offers administrators valuable insights for informed decision-making. The feature enhances operational efficiency and strategic planning within the college.'
        },
        {
            header: 'Document Management and Generation',
            text: 'Volmint CMS facilitates comprehensive document management. Thus, ensuring organized storage and efficient retrieval of essential records. It also simplifies the generation of various documents, streamlining administrative tasks and reducing paperwork.'
        },
        {
            header: 'On-time Notifications',
            text: 'The platform ensures timely communication by sending notifications through multiple channels such as SMS, WhatsApp, and email. The feature enhances communication efficiency, keeping stakeholders informed promptly about important updates and events.'
        },
        {
            header: '24*7 Onsite & Online Service Support',
            text: 'Volmint CMS provides round-the-clock onsite and online service support, ensuring that technical issues are addressed promptly. This commitment to continuous support enhances the reliability and functionality of the system for uninterrupted operations.'
        },
    ];

    offerings = [
        {
            text: 'Lifetime Free Maintenance'
        },
        {
            text: 'Personalized Assistance'
        },
        {
            text: 'Lifetime Free Maintenance Updates'
        },
        {
            text: 'Discounts on Extra Modules'
        },
        {
            text: 'Feedback and Feature Request'
        },
        {
            text: 'Third-Party Tool Integration'
        },
        {
            text: 'Consultation and Best Practices'
        },
        {
            text: 'Training Sessions'
        },
        {
            text: 'Resolution within 48 hours'
        },
        {
            text: 'Free Server'
        },
    ];

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private contactService: ContactUsService,
        private alertService: AlertService
    ) {
    }

    ngOnInit(): void {
        this.refresh();
    }

    refresh() {
        this.contactForm = this.fb.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            contact: [''],
            subject: ['', Validators.required],
            message: ['', Validators.required]
        });
    }

    openModal() {
        this.isModalOpen = true;
    }

    closeModal() {
        this.isModalOpen = false;
    }

    handleOverlayClick(event: MouseEvent) {
        const target = event.target as HTMLElement;
        if (!target.closest('.modal-dialog')) {
            this.closeModal();
        }
    }

    submit() {
        this.submitted = true;
        if (!this.contactForm.valid) {
            return;
        }
        if (this.submitted) {
            const params = {
                'name': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().name) ? this.contactForm.getRawValue().name : '',
                'email': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().email) ? this.contactForm.getRawValue().email : '',
                'contact': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().contact) ? this.contactForm.getRawValue().contact : '',
                'subject': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().subject) ? this.contactForm.getRawValue().subject : '',
                'message': (this.contactForm && this.contactForm.getRawValue() && this.contactForm.getRawValue().message) ? this.contactForm.getRawValue().message : '',
            };
            this.contactService.create(params).subscribe(data => {
                this.successMessage = 'Your form has been submitted successfully!';
                this.submitted = false;
                this.contactForm.patchValue({
                    'name': '',
                    'email': '',
                    'contact': '',
                    'subject': '',
                    'message': ''
                });
            }, error => {
                this.submitting = false;
                this.submitted = false;
                if (error && error['error'] && error['error']['errors'] && error['error'].hasOwnProperty('errors')) {
                    if (error['error']['errors'].email && error['error']['errors'].email.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].email[0]);
                        return;
                    }
                    if (error['error']['errors'].name && error['error']['errors'].name.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].name[0]);
                        return;
                    }
                    if (error['error']['errors'].contact && error['error']['errors'].contact.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].contact[0]);
                        return;
                    }
                    if (error['error']['errors'].subject && error['error']['errors'].subject.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].subject[0]);
                        return;
                    }
                    if (error['error']['errors'].message && error['error']['errors'].message.length > 0) {
                        this.alertService.showErrors(error['error']['errors'].message[0]);
                        return;
                    }
                }
            });
        }
    }

    playVideo() {
        const video = this.videoElement.nativeElement;
        const thumbnail = document.getElementById('video-thumbnail');
        if (video) {
            video.classList.remove('d-none');
            video.play();
            if (thumbnail) {
                thumbnail.style.display = 'none';
            }
            const playButton = document.querySelector('.play-button');
            if (playButton) {
                playButton.classList.add('d-none');
            }
        }
    }

    goTo(url) {
        this.router.navigateByUrl(url);
    }

}
