import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {BlogService} from '../../../shared/services/blog.service';
import {Router} from '@angular/router';


@Component({
    selector: 'app-home-four',
    templateUrl: './home-four.component.html',
    styleUrls: ['./home-four.component.scss']
})
export class HomeFourComponent implements OnInit {
    @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
    blogs: any;
    image1Src = 'assets/img/landing-new/all.png';
    image2Src = 'assets/img/landing-new/all1.png';
    activeCategory: string = 'all';
    slides = [];
    currentSlide = 0;
    interval: any;
    reviews = [
        {
            image: 'assets/img/landing-new/clients-part/shefali.png',
            name: 'Shefali Vihod',
            review: 'As an employee at Volmint Technology, I can confidentially say that this company is a fantastic place to work! from day one, I have been impressed by the positive and innovative culture that permeates every aspect of the organisation.'
        },
        {
            image: 'assets/img/landing-new/clients-part/radhika.png',
            name: 'Radhika Tanwar',
            review: 'Wonderful Edtech platform! It provides interesting, customized items that meet various needs. Its a great options for educators and students because of its user friendly layout and first rate customer assistance.'
        },
        {
            image: 'assets/img/landing-new/clients-part/vaibhav-gupta.png',
            name: 'Vaibhav Gupta',
            review: 'Their University Management System is a one stop for all needs to make online learning better. It enhance the efficiency and improves the communication between educators and students.'
        },
        {
            image: 'assets/img/landing-new/clients-part/pankaj.png',
            name: 'Pankaj Drall',
            review: 'Since the last few years, we had faced issues in maintaining our accounts data and that led us to Volmint\'s Accounts Management System. The software is convenient to use and help us to manage our financial transactions and expenses. Highly Recommended!'
        },
        {
            image: 'assets/img/landing-new/clients-part/nandni.png',
            name: 'Nandani Uttwal',
            review: 'Being a B2B platform, we purchased cms to assist us in maintaining the data and morals of our business. The software is very helpful, and reconciliation can be finished with only one click.'
        },
        {
            image: 'assets/img/landing-new/clients-part/abhishek.png',
            name: 'Abhishek Chaudhary',
            review: 'Volmint does research on the characteristics of technology and the needs of the system, making sure it works with the operating system and program. As a business-to-business platform, we purchased a content management system to manage our data and values. This software is useful and solves problem with one click.'
        }
    ];

    constructor(private blogData: BlogService, public router: Router) {
    }

    ngOnInit(): void {
        this.get();
        this.slides = this.chunkArray(this.reviews, 3);
        this.startSlider();
    }

    chunkArray(array: any[], size: number): any[][] {
        const result: any[][] = [];
        for (let i = 0; i < array.length; i += size) {
            result.push(array.slice(i, i + size));
        }
        return result;
    }

    startSlider() {
        this.interval = setInterval(() => {
            this.nextSlide();
        }, 8000);
    }

    nextSlide() {
        this.currentSlide = (this.currentSlide + 1) % this.slides.length;
        this.updateSlider();
    }

    updateSlider() {
        const slider = document.getElementById('slider');
        if (slider) {
            slider.style.transform = `translateX(-${this.currentSlide * 100}%)`;
        }
        this.updateIndicators();
    }

    updateIndicators() {
        const indicators = document.querySelectorAll('.slider-indicators .indicator');
        indicators.forEach((indicator, index) => {
            indicator.classList.toggle('active', index === this.currentSlide);
        });
    }

    truncateText(text: string, wordLimit: number): string {
        if (!text) return '';
        const words = text.split(' ');
        if (words.length <= wordLimit) return text;
        return words.slice(0, wordLimit).join(' ') + '...';
    }

    get(params: any = {}) {
        this.blogs = [];
        this.blogData.get(params).subscribe(data => {
            if (data && Array.isArray(data.data)) {
                this.blogs = data.data;
            }
        });
    }

    changeImages(category: string): void {
        this.activeCategory = category;
        switch (category) {
            case 'all':
                this.image1Src = 'assets/img/landing-new/all.png';
                this.image2Src = 'assets/img/landing-new/all1.png';
                break;
            case 'mobile':
                this.image1Src = 'assets/img/landing-new/mobile-app.jpg';
                this.image2Src = 'assets/img/landing-new/mobile-app1.jpg';
                break;
            case 'product':
                this.image1Src = 'assets/img/landing-new/product-design.jpg';
                this.image2Src = 'assets/img/landing-new/1.jpg';
                break;
            case 'web':
                this.image1Src = 'assets/img/landing-new/web-design1.jpg';
                this.image2Src = 'assets/img/landing-new/web-design2.jpg';
                break;
            default:
                this.image1Src = 'assets/img/landing-new/all.png';
                this.image2Src = 'assets/img/landing-new/all1.png';
        }
    }

    playVideo() {
        const video = this.videoElement.nativeElement;
        const thumbnail = document.getElementById('video-thumbnail');

        if (video) {
            video.classList.remove('d-none');
            video.style.display = 'block';
            video.play();

            if (thumbnail) {
                thumbnail.style.display = 'none';
            }

            const playButton = document.querySelector('.play-button');
            if (playButton) {
                playButton.classList.add('d-none');
            }
        }
    }

    goTo(url) {
        this.router.navigateByUrl(url);
    }
}
