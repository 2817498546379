<section class="banner">
    <div class="container-fluid bg mt-5">
        <div class="container mt-5 pt-5">
            <div class="row g-5">
                <div class="col-md-6 d-flex text-center text-md-start align-items-center">
                    <div>
                        <h1 class="fw-bolder font-56"><span class="brand-primary-text-color">CGMS-</span>
                            The Ultimate Tool for Managing College Groups</h1>
                        <p class="my-4 fs-6 text-secondary text-wrap f-20 justify-text">The College Group Management
                            System helps your
                            institute to work in a more
                            advanced way. It enhances collaboration and engagement among learners and faculty involved
                            in
                            various campus groups. The CGMS system is a group collaboration software that aims to
                            enhance
                            communication, coordination, and collaboration.
                        </p>
                        <button class="btn bg-white border-0 px-5 rounded-0 fw-bold hover-effect"
                                (click)="openModal()">View Demo <i
                            class="ms-3 fa-solid fa-arrow-right"></i>
                        </button>
                        <ng-template #contactFormModal>
                            <app-contact-form [isModalOpen]="isModalOpen"
                                              (closeModal)="closeModal()"></app-contact-form>
                        </ng-template>
                        <div *ngIf="isModalOpen" class="modal-overlay" (click)="handleOverlayClick($event)">
                            <ng-container *ngTemplateOutlet="contactFormModal"></ng-container>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 d-flex justify-content-end py-5 d-flex justify-content-center">
                    <div class="position-relative">
                        <img src="assets/img/landing-new/thumbnail/college%20Group%20management%20system%20banner.jpg"
                             class="img-fluid rounded-4" alt="Banner Image" id="video-thumbnail">
                        <button class="play-button position-absolute" (click)="playVideo()"></button>
                        <video #videoElement class="d-none rounded-4" controls>
                            <source src="assets/img/landing-new/cgms/cgms.webm" type="video/webm">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="container-fluid">
    <div class="container py-5">
        <div class="row ">
            <div class="col-md-6 pt-5 text-right">
                <div class="card bg-color-blue rounded-4 p-5 h-75 d-flex justify-content-center border-0 shadow-lg">
                    <div class="card-body mt-2">
                        <img src="assets/img/landing-new/cgms/cgms-dashboard.png" class="mt-2" alt="card-image">
                    </div>
                </div>
            </div>
            <div class="col-md-6 ps-md-5 py-5">
                <h2 class=" fs-1 fw-bolder">About Volmint’s
                    <span class="brand-primary-text-color"> College Group Management System</span></h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">A College Group Management System is
                    a sophisticated
                    software solution designed to
                    enhance the organizational efficiency of group-related activities within an educational institution.
                    Created for colleges and universities, this system serves as a centralized platform for coordinating
                    and optimizing the functioning of various student groups, clubs, and organizations on campus. It
                    facilitates seamless communication, collaboration, and coordination among group members, faculty
                    advisors, and administrators.</p>
                <img src="assets/img/project/cms-.png" class="mt-5" alt="">
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-md-6 py-5">
                <h2 class=" fs-1 fw-bolder">Optimize Campus Efficiency with Our Robust
                    <span class="brand-primary-text-color">CGMS</span></h2>
                <p class="fs-6 text-secondary text-wrap justify-text">Volmint’s College Group Management System
                    offers robust and
                    advanced software to
                    perform countless activities within an institution. It offers secure college data management along
                    with an academic team management system. CGMS software provides a centralized platform for faculty,
                    students, and administrative staff to communicate.
                </p>
                <div class="d-lg-flex justify-content-between w-90 flex-wrap d-none">
                    <div
                        class=" my-1 px-5 py-3 brand-primary-bg text-white fixed-width-span text-center text-secondary"
                        *ngFor="let item of powerfullToUse">
                        {{ item.text }}
                    </div>
                </div>
                <div class="col-12 d-block d-lg-none mt-4">
                    <p class="fs-6 fw-bold text-secondary" *ngFor="let item of powerfullToUse"><img
                        src="assets/img/project/check.png"
                        alt=""
                        class="img-fluid"> {{ item.text }}
                    </p>
                </div>
            </div>
            <div class="col-md-6 pt-5 text-right">
                <img src="assets/img/project/ums-2.png" class="mt-2" alt="">
            </div>
        </div>
    </div>
</div>
<div class="container-fluid bg-purple">
    <div class="container">
        <div class="row py-5">

            <div class="col-md-6 py-5">
                <h2 class=" fs-1 fw-bolder">
                    <span class="brand-primary-text-color">Key Features</span> College Group Management System</h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">A college group management system has
                    countless
                    collaborative tools for students and teachers. It automates several processes, reduces the manual
                    workload, and improves the college administration experience.
                </p>
                <div class="row g-3">
                    <div class="col-sm-4 col-6 px-2 my-2"
                         *ngFor="let item of features">
                        <div class="card bg-white text-center py-2 border-0 shadow-lg p-4">
                               <span>
                            <img src="{{item.icon}}" alt="" class="img-fluid w-25">
                        </span>
                            <span class="ms-2 text-secondary">
                            {{ item.text }}
                        </span>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-md-6 pt-5 d-flex justify-content-md-end">
                <img src="assets/img/project/cgms-2.png" alt="Image">
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="container">
        <div class="row ">
            <div class="col-md-6 py-5">
                <h2 class=" fs-1 fw-bolder">
                    Manage <span class="brand-primary-text-color">Multiple Colleges </span> From Single Dashboard</h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">Volmint’s CGMS allows to manage and
                    control multiple
                    colleges under the roof of a single platform. It creates a collaborative approach for the different
                    users and members to work smoothly and effectively.</p>
                <button class="btn text-white brand-primary-bg border px-5 rounded-0 my-5 hover-white fw-bold"
                        (click)="openModal()">Book Appointment
                    <i
                        class="ms-3 fa-solid fa-arrow-right"></i>
                </button>
            </div>
            <div class="col-md-6 pt-5  text-right">
                <img src="assets/img/project/cgms-4.png" class="ms-2 mt-2 " alt="">
            </div>
            <div class="col-md-6 pt-5  text-right">
                <img src="assets/img/project/cgms-5.png" class="ms-2 mt-2 " alt="">
            </div>
            <div class="col-md-6 py-5 ps-md-5">
                <h2 class=" fs-1 fw-bolder">
                    Why Do You Need <span class="brand-primary-text-color">CGMS Software </span></h2>
                <p class="my-4 fs-6 text-secondary text-wrap justify-text">College Group Management Software is
                    essential for
                    efficient organization and coordination within educational institutions. It streamlines various
                    aspects of group activities, fostering collaboration and enhancing productivity.</p>
                <div class="row g-3 mt-5 ">
                    <div class="col-sm-6 col-12 px-0 my-3 "
                         *ngFor="let item of offerings">
                        <span>
                            <img src="assets/img/project/check.png" alt="" class="img-fluid">
                        </span>
                        <span class="ms-2 text-secondary">
                            {{ item.text }}
                        </span>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<div class="container-fluid brand-primary-bg mt-5">
    <div class="container py-5">
        <h1 class="text-white text-center mt-5 fw-bold">What Makes Our College Group Management System Stand Out</h1>
        <p class="text-white text-center justify-text">Volmint College Group Management System (VCGM) stands out
            as a
            comprehensive
            cloud-based solution, revolutionizing college administration. Here are compelling reasons to choose us:
        </p>
        <div class="row gy-5 py-5">
            <div class="col-md-4" *ngFor="let item of whyChoose; let i = index">
                <div class="h-w-40 text-center rounded-circle bg-white pt-2 fw-bold text-black ">{{ i + 1 }}</div>
                <h3 class="text-white my-4 fw-bold">{{ item.header }}</h3>
                <p class="text-white justify-text">{{
                        item.text
                    }}.</p>
            </div>

        </div>
    </div>
</div>

<div class="container-fluid py-5">
    <div class="container">
        <div class="row py-5 gx-5">
            <div class="col-md-7 py-5">
                <h2 class=" fs-1 fw-bolder">Explore Our Comprehensive
                    <span class="brand-primary-text-color">CGMS Modules </span>
                </h2>
                <p class="my-5 fs-6 text-secondary text-wrap justify-text">Volmint College Group Management
                    Software offers prominent
                    modules, designed precisely for the needs and requirements of the institute, students, and
                    faculty.</p>
                <div class="row g-3 mt-5">
                    <div class="col-12 px-0 my-3 "
                         *ngFor="let item of modules">
                        <span>
                            <img src="assets/img/project/check.png" alt="" class="img-fluid">
                        </span>
                        <span class="ms-2 text-secondary">
                            {{ item.text }}
                        </span>
                    </div>
                </div>

            </div>
            <div class="col-md-5 pt-5 d-flex justify-content-end">
                <img src="assets/img/project/ums-5.png" class="img-fluid " alt="">
            </div>
        </div>
        <!--   <div class="row py-5 gx-5">
               <div class="col-md-6 py-5">
                   <h2 class=" fs-1 fw-bolder">
                       What Our Customers Says?
                   </h2>
                   <div class="card rounded-4 g-3 mt-5 p-5">
                       <div id="carouselExampleIndicators" class="carousel  slide" data-bs-ride="carousel">
                           <div class="carousel-inner">
                               <div class="carousel-item active mb-5">

                                   <div class="d-flex border-bottom pb-4   justify-content-between align-items-center">
                                       <div class="d-flex w-50 ">
                                           <img src="assets/img/landing-new/reviews/cgms2.png" alt=""
                                                class="rounded-circle w-25">
                                           <span class="ms-4 mt-2">
                                                <h4>Kunal Sharma</h4>
                                                <span>New Delhi</span>
                                           </span>
                                       </div>
                                       <div>
                                           <img src="assets/img/project/comma.png" alt="">
                                       </div>
                                   </div>
                                   <p class="text-secondary text-wrap fw-bold justify-text">Volmint’s CGMS has made managing our campus
                                       groups so much easier. The communication
                                       tools and real-time updates keep everyone on the same page, and the role-based
                                       access ensures our data is secure.</p>
                               </div>
                               <div class="carousel-item mb-5">
                                   <div class="d-flex border-bottom pb-4 justify-content-between align-items-center">
                                       <div class="d-flex w-50 ">
                                           <img src="assets/img/landing-new/reviews/cgms1.avif" alt=""
                                                class="rounded-circle w-25">
                                           <span class="ms-4 mt-2">
                                                <h4>Shreya Rana</h4>
                                                <span>Gurugram</span>
                                           </span>
                                       </div>
                                       <div>
                                           <img src="assets/img/project/comma.png" alt="">
                                       </div>
                                   </div>
                                   <p class="text-secondary text-wrap fw-bold justify-text">The CGMS has been fantastic for organizing
                                       our student clubs and activities. The user-friendly interface and robust features
                                       like task automation and document management have improved our workflow. Plus, the
                                       support team is always there when we need them.</p>
                               </div>
                               <div class="carousel-item mb-5">
                                   <div class="d-flex border-bottom pb-4 justify-content-between align-items-center">
                                       <div class="d-flex w-50 ">
                                           <img src="assets/img/landing-new/reviews/cgms3.jpeg" alt=""
                                                class="rounded-circle w-25">
                                           <span class="ms-4 mt-2">
                                                <h4>Siddharth Sharma</h4>
                                                <span>Noida</span>
                                           </span>
                                       </div>
                                       <div>
                                           <img src="assets/img/project/comma.png" alt="">
                                       </div>
                                   </div>
                                   <p class="text-secondary text-wrap fw-bold justify-text">Since we started using Volmint’s CGMS, our
                                       campus groups have become more connected and productive. The system’s ability to
                                       handle event promotions and provide detailed analytics has been incredibly useful.
                                   </p>
                               </div>
                           </div>
                       </div>
                       <a class="carousel-control-prev bg-purple rounded-circle h-w-40 bottom-0"
                          href="#carouselExampleIndicators" role="button"
                          data-bs-slide="prev">
                           <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                           <span class="visually-hidden">Previous</span>
                       </a>
                       <a class="carousel-control-next bg-purple  rounded-circle h-w-40" href="#carouselExampleIndicators"
                          role="button"
                          data-bs-slide="next">
                           <span class="carousel-control-next-icon" aria-hidden="true"></span>
                           <span class="visually-hidden text-black">Next</span>
                       </a>
                   </div>
                   <div class="card bg-color-blue py-5 mt-4">
                       <p class="fs-1 fw-bold text-center color-white pt-4">Any Questions?</p>
                       <div class="d-flex justify-content-center py-5">
                           <button class="btn bg-white rounded-0 py-3 fs-5 text-black w-50 rounded-4"
                                   (click)="goTo('/contact')">Contact Us
                           </button>
                       </div>
                   </div>
               </div>
               <div class="col-md-6 pt-5">

                   <div class=" w-100 ps-md-5">
                       <h2 class=" fs-1 fw-bolder">
                           Book Appointment Now!
                       </h2>
                       <div *ngIf="successMessage" class="alert alert-success my-2 fw-bold">
                           {{ successMessage }}
                       </div>
                       <form [formGroup]="contactForm" action="" class="mt-5 w-100 rounded-4 px-3 py-4 p-md-5"
                             style="background: #F4F4F4">
                           <div class="form-floating mb-5 rounded-3 outline-0 shadow border-0">
                               <input type="name" class="form-control" id="name" formControlName="name"
                                      placeholder="Enter Your Name">
                               <label for="name">Name</label>
                               <small *ngIf="submitted && contactForm.get('name').hasError('required')"
                                      class="text-danger">
                                   Field is required.
                               </small>
                           </div>
                           <div class="form-floating mb-5 rounded-3 outline-0 shadow border-0">
                               <input type="email" class="form-control" id="email" formControlName="email"
                                      placeholder="name@example.com">
                               <label for="email">Email</label>
                               <small *ngIf="submitted && contactForm.get('email').hasError('required')"
                                      class="text-danger">
                                   Field is required.
                               </small>
                           </div>
                           <div class="form-floating mb-5 rounded-3 outline-0 shadow border-0">
                               <input type="number" class="form-control" id="contact" formControlName="contact"
                                      placeholder="Enter Your Phone Number">
                               <label for="contact">Phone Number</label>
                               <small *ngIf="submitted && contactForm.get('contact').hasError('required')"
                                      class="text-danger">
                                   Field is required.
                               </small>
                           </div>
                           <div class="form-floating mb-5 rounded-3 outline-0 shadow border-0">
                               <input type="text" class="form-control" id="subject" formControlName="subject"
                                      placeholder="Subject">
                               <label for="subject">Subject</label>
                               <small *ngIf="submitted && contactForm.get('subject').hasError('required')"
                                      class="text-danger">
                                   Field is required.
                               </small>
                           </div>
                           <div class="form-floating mb-3 rounded-3 outline-0 shadow border-0">
                               <textarea class="form-control" placeholder="Leave a message here" id="message"
                                         style="resize: none;"
                                         formControlName="message"></textarea>
                               <label for="message">Message</label>
                               <small *ngIf="submitted && contactForm.get('message').hasError('required')"
                                      class="text-danger">
                                   Field is required.
                               </small>
                           </div>

                           <button class="btn rounded-pill brand-primary-bg text-white mt-5 w-100 hover-white"
                                   (click)="submit()">Submit
                           </button>
                       </form>
                   </div>
               </div>
           </div>-->
    </div>
</div>

<div class="container-fluid mt-5">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="col-12">
                    <img src="assets/img/landing-new/contact-us/faq.png" alt="Image" class="img-fluid"/>
                    <p class="fs-1 fw-bold">Frequently Asked Questions</p>
                    <p class="text-secondary">Have a question that is not answered? You can contact us at</p>
                    <p class="primary-blue fw-bold">Email: <a href="mailto:support@volmint.com"
                                                              class="text-decoration-underline primary-blue">support@volmint.com</a>
                    </p>
                    <button type="submit"
                            class="bg-color-blue border-0 color-white px-5 py-2 fs-6 mt-4 fw-bold hover-effect"
                            (click)="goTo('/faq')">
                        View All Faqs
                    </button>
                </div>
            </div>
            <div class="col-12 col-md-6 mt-4">
                <div class="accordion" id="faqAccordion">
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <span class="fs-6 fw-bold" style="color: #5236FF;">  <div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp; What can Volmint’s College Group Management System (CGMS) do for our campus?</span>
                            </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Volmint’s CGMS centralizes and streamlines all group-related activities, enhancing
                                communication and collaboration among students, faculty, and administrators. It
                                simplifies task management, automates processes, and ensures efficient coordination of
                                campus groups and clubs.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        <span
                            class="fs-6 fw-bold" style="color: #5236FF;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp; How does CGMS improve communication and collaboration on campus?</span>
                            </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                CGMS offers advanced communication tools and real-time updates that keep everyone
                                connected and informed. Whether it’s through SMS, WhatsApp, or email, the system ensures
                                that all members of college groups are in sync and can collaborate effectively.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                <span class="fs-6 fw-bold" style="color: #5236FF;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp; Is the CGMS secure and how does it handle sensitive information?</span>
                            </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Yes, the CGMS prioritizes security with role-based login access, ensuring that only
                                authorized individuals can access sensitive data. This helps maintain confidentiality
                                and integrity of all information, protecting it from unauthorized access.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingFour">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                <span class="fs-6 fw-bold" style="color: #5236FF;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp; How does the CGMS assist with event management and promotion?</span>
                            </button>
                        </h2>
                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                The system includes tools for event promotion and marketing, making it easy to organize
                                and publicize campus events. This feature helps in managing events efficiently and
                                ensuring they reach the intended audience effectively.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item shadow-lg border-0">
                        <h2 class="accordion-header" id="headingFive">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                <span class="fs-6 fw-bold" style="color: #5236FF;"><div class="encircled-icon">
        <i class="fas fa-question fw-bold"></i>
    </div> &nbsp; What kind of support does Volmint offer for CGMS users?</span>
                            </button>
                        </h2>
                        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                             data-bs-parent="#faqAccordion">
                            <div class="accordion-body fs-6 text-secondary justify-text">
                                Volmint provides 24/7 onsite and online support to address any technical issues quickly.
                                This ensures that the system remains reliable and fully operational, with assistance
                                available whenever needed.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid ums-bottom-bg ps-5 mt-5">
    <div class="container">
        <div class="row align-items-center py-5">
            <div class="col-md-10">
                <h1 class="text-white">Ready to elevate your university management to next level?</h1>
            </div>
            <div class="col-md-2">
                <button class="btn bg-white rounded-0 px-5 py-3 fw-bold text-black hover-effect"
                        (click)="goTo('/contact')">Contact Us
                </button>
            </div>
        </div>
    </div>
</div>
