<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <h2>Employee Profile info</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Profile</li>
            </ul>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</div>

<section class="bg-light">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 mb-4 mb-sm-5">
                <div class="card card-style1 border-0">
                    <div class="card-body p-1-9 p-sm-2-3 p-md-6 p-lg-7">
                        <div class="row align-items-center">
                            <div class="col-lg-4 mb-4 mb-lg-0 text-center">
                                <img [src]="idCard?.profile_image?.url" alt="..." >
                            </div>
                            <div class="col-lg-8 px-xl-10 font-cursive" >
                                <div class="bg-secondary d-lg-inline-block py-1-9 px-1-9 px-sm-6 mb-1-9 rounded" style=" width: -webkit-fill-available;">
                                    <h3 class="h2 text-white mb-0">{{idCard?.name}}</h3>
                                    <span class="text-primary">{{idCard?.designation}}</span>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <ul class="list-unstyled mb-1-9">
                                            <li class="mb-2 mb-xl-3 display-28"><span class="display-26 text-secondary me-2 font-weight-600">Employee Id:</span>
                                                {{idCard?.emp_no}}</li>
                                            <li class="mb-2 mb-xl-3 display-28"><span class="display-26 text-secondary me-2 font-weight-600">Date of Joining:</span> {{idCard?.date_of_joining}}</li>
                                            <li class="mb-2 mb-xl-3 display-28"><span class="display-26 text-secondary me-2 font-weight-600">Email:</span> {{idCard?.email}}</li>
                                            <li class="mb-2 mb-xl-3 display-28"><span class="display-26 text-secondary me-2 font-weight-600">Contact:</span>{{idCard?.contact}}</li>
                                            <li class="mb-2 mb-xl-3 display-28"><span class="display-26 text-secondary me-2 font-weight-600">Father Name:</span>{{idCard?.father_name}}</li>

                                        </ul>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                        <ul class="list-unstyled mb-1-9">
                                            <li class="mb-2 mb-xl-3 display-28"><span class="display-26 text-secondary me-2 font-weight-600">Highest Education:</span>{{idCard?.highest_qualification}}</li>
                                            <li class="mb-2 mb-xl-3 display-28"><span class="display-26 text-secondary me-2 font-weight-600">Gender:</span>{{idCard?.type}}</li>
                                            <li class="mb-2 mb-xl-3 display-28"><span class="display-26 text-secondary me-2 font-weight-600">Marital Status:</span>{{idCard?.marital_status}}</li>
                                            <li class="mb-2 mb-xl-3 display-28 "><span class="display-26 text-secondary me-2 font-weight-600">Address:</span>{{idCard?.address}}</li>
                                            <li class="mb-2 mb-xl-3 display-28"><span class="display-26 text-secondary me-2 font-weight-600">Date of Birth:</span>{{idCard?.dob}}</li>
                                            <!-- <li class="mb-2 mb-xl-3 display-28 ds-font" style="font-family: 'Cedarville Cursive' !important;"><span class="display-26  me-2 font-weight-600">Digital Signature:</span>{{idCard?.digital_signature}}</li>-->
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
