<!-- Start Page Title Area -->
<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <p class="fs-5 color-white text-center">Our Projects</p>
            <p class="fs-1 color-white text-center fw-bold">Innovation drives our technology forward.</p>
            <div class="mt-5 text-center">
                <button class="bg-F5FCFF px-5 py-3 border-0 fs-6 hover-effect fw-bold" (click)="goTo('/contact')">
                    Contact Us <i class="fa fa-arrow-right" aria-hidden="true"></i>
                </button>
            </div>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</div>

<section class="product-cards bg-pink">
    <div class="container-fluid mt-5">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-12 d-flex justify-content-center">
                    <img src="assets/img/landing-new/projects/project.jpg" alt="Image"
                         class="img-fluid shadow-lg border-0 rounded-4"/>
                </div>
                <div class="col-md-6 col-12">
                    <p class="fs-1 fw-bolder">About Our Company</p>
                    <p class="text-secondary f-20 text-wrap justify-text fs-6">At Volmint Technologies, we’re committed
                        to
                        revolutionizing technology
                        solutions, fueling innovation, and delivering unparalleled expertise to propel businesses
                        into the
                        digital forefront. Our dedicated support team ensures technology works for you, providing
                        world-class software, offering proactive maintenance, and quick troubleshooting.

                        Being one of the most preferred IT giants, we provide customized solutions that are relevant
                        to your
                        requirements for the betterment of your organization. We have crafted numerous software such
                        as
                        University Management Systems, Enroll, College Management Systems, ITR Software, and
                        E-Clinic
                        digital platform. The products are designed to automate the organization’s internal process
                        with
                        ease of technology.

                        Our team specializes in creating custom digital solutions for businesses, focusing on
                        functional and
                        forward-thinking experiences. We integrate cutting-edge technology, including AI, machine
                        learning,
                        cloud computing, and IoT, into existing systems. Our expert consultants offer strategic
                        insights and
                        practical advice to optimize IT infrastructure, streamline operations, and achieve business
                        goals,
                        making us a trusted partner in digital transformation.</p>
                </div>
            </div>
        </div>
    </div>
    <section class="intro">
        <div class="container-fluid mt-5 bg-purple py-5">
            <div class="container">
                <p class="color-white fs-1 fw-bold text-center">The Core Values Behind Our Work</p>
                <div class="row g-5">
                    <div class="col-12 col-lg-3 card-container">
                        <div class="card rounded-5 p-2">
                            <div class="row">
                                <div class="col-6">
                                    <img src="assets/img/landing-new/about-us/about-us3.1.png" alt="Image"
                                         class="img-fluid h-75"/>
                                </div>
                            </div>
                            <div class="card-body">
                                <p class="fs-5 fw-bold ">Efficiency</p>
                                <p class="text-secondary">We prioritize timely and effective
                                    project completion,
                                    optimizing
                                    every process to provide the best solutions without waiting, ensuring timely and
                                    budget-friendly outcomes.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-3 card-container">
                        <div class="card rounded-5 p-2">
                            <div class="row">
                                <div class="col-6">
                                    <img src="assets/img/landing-new/about-us/about-us3.2.png" alt="Image"
                                         class="img-fluid h-75"/>
                                </div>
                            </div>
                            <div class="card-body">
                                <p class="fs-5 fw-bold">Accountability</p>
                                <p class="text-secondary">Trust is earned through reliability, transparency, honesty,
                                    integrity, dedication, and consistency, ensuring commitments are met. These
                                    qualities make us trustworthy.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-3 card-container">
                        <div class="card rounded-5 p-3">
                            <div class="row">
                                <div class="col-6">
                                    <img src="assets/img/landing-new/about-us/about-us3.3.png" alt="Image"
                                         class="img-fluid h-75"/>
                                </div>
                            </div>
                            <div class="card-body">
                                <p class="fs-5 fw-bold">Commitment</p>
                                <p class="text-secondary">Our mission is to ensure your success through our passion &
                                    dedication, crafting innovative solutions with exceptional care & precision to
                                    turn your vision into reality.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-3 card-container">
                        <div class="card rounded-5 p-3">
                            <div class="row">
                                <div class="col-6">
                                    <img src="assets/img/landing-new/about-us/about-us3.4.png" alt="Image"
                                         class="img-fluid h-75"/>
                                </div>
                            </div>
                            <div class="card-body">
                                <p class="fs-5 fw-bold">Team Work</p>
                                <p class="text-secondary">We value collaboration, combining
                                    expertise and
                                    creativity to
                                    tackle challenges and achieve excellence, fostering innovation and delivering
                                    exceptional results.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid py-5">
            <div class="image-slider">
                <div class="image-slider-wrapper">
                    <div class="col-2">
                        <img src="assets/img/landing-new/company-logos/education-mitra.jpg" alt="Image"
                             class="img-fluid rounded-3"/>
                    </div>
                    <div class="col-2">
                        <img src="assets/img/landing-new/company-logos/edutantra.jpg" alt="Image"
                             class="img-fluid rounded-3"/>
                    </div>
                    <div class="col-2">
                        <img src="assets/img/landing-new/company-logos/sgvu.jpg" alt="Image"
                             class="img-fluid rounded-3"/>
                    </div>
                    <div class="col-2">
                        <img src="assets/img/landing-new/company-logos/dr-axico.jpg" alt="Image"
                             class="img-fluid rounded-3"/>
                    </div>
                    <div class="col-2">
                        <img src="assets/img/landing-new/company-logos/ms.jpg" alt="Image"
                             class="img-fluid rounded-3"/>
                    </div>
                    <div class="col-2">
                        <img src="assets/img/landing-new/company-logos/nation-news.jpg" alt="Image"
                             class="img-fluid rounded-3"/>
                    </div>
                    <div class="col-2">
                        <img src="assets/img/landing-new/company-logos/subharti-logo.jpg" alt="Image"
                             class="img-fluid rounded-3"/>
                    </div>
                    <div class="col-2">
                        <img src="assets/img/landing-new/company-logos/vgu-logo%20.png" alt="Image"
                             class="img-fluid rounded-3"/>
                    </div>
                    <div class="col-2">
                        <img src="assets/img/landing-new/company-logos/sdlc.jpg" alt="Image"
                             class="img-fluid rounded-3"/>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="listed-products">
        <div class="container-fluid mt-5">
            <p class="fs-1 text-center color-purple fw-bolder my-5">Our Projects</p>
            <div class="container">
                <div class="row g-5">
                    <div class="col-lg-4 col-md-6 col-12" *ngFor="let card of products">
                        <div class="card p-1 border-0 shadow-lg" style="cursor: pointer">
                            <img src="{{card.image}}" alt="Card Image" class="card-img shadow-lg bg-white">
                            <div class="card-content p-4">
                                <div class="row">
                                    <div class="col-6 d-flex justify-content-start align-items-center">
                                        <div class="logo-wrapper shadow-lg">
                                            <img src="{{card.logo}}" alt="Logo" class="img-fluid"
                                                 [ngStyle]="{'height': card.height, 'width': card.width}"/>
                                        </div>
                                    </div>
                                    <div class="col-6 d-flex justify-content-end">
                                        <h2 class="card-title fs-3 fw-bold mt-4">{{ card.title }}</h2>
                                    </div>
                                </div>
                                <p class="card-text justify-text">{{ card.description }}</p>
                            </div>
                            <p class="card-button fw-bold" style="cursor: pointer;" (click)="goTo(card.url)">Know
                                More</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>

<div class="container-fluid py-5"
     style="background-image: url('/assets/img/landing-new/footer/footer-bg.png');">
    <div class="container">
        <div class="row text-center text-md-start">
            <div class="col-md-6 col-12 fs-1 color-white fw-bold mb-3 mb-md-0">
                Ready to take your Business’s IT to the next level?
            </div>
            <div class="col-md-6 col-12 text-md-end">
                <button class="bg-F5FCFF px-5 py-3 border-0 fs-6 hover-effect fw-bold" (click)="goTo('/contact')">
                    Contact Us <i class="fa fa-arrow-right" aria-hidden="true"></i>
                </button>
            </div>
        </div>
    </div>
</div>
