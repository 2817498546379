<section class="banner">
    <div class="container-fluid video-bg">
        <video autoplay muted loop>
            <source src="assets/img/landing-new/gallery/gallery-banner.m4v" type="video/mp4">
            Your browser does not support the video tag.
        </video>
    </div>
</section>
<div class="container-fluid mt-5 pt-md-5">
    <div class="container">
        <div class="row g-4">
            <div class="col-md-3 col-12">
                <div class="card card-hover shadow-lg rounded-4 h-100 p-2">
                    <img src="assets/img/landing-new/gallery/gallery1.jpeg" alt="Image"
                         class="h-100 img-fluid card-img-hover"/>
                </div>
            </div>
            <div class="col-md-3 col-12">
                <div class="card card-hover shadow-lg rounded-4 h-100 p-2">
                    <img src="assets/img/landing-new/gallery/gallery2.jpeg" alt="Image"
                         class="h-100 img-fluid card-img-hover"/>
                </div>
            </div>
            <div class="col-md-3 col-12">
                <div class="card card-hover shadow-lg rounded-4 h-100 p-2">
                    <img src="assets/img/landing-new/gallery/gallery3.jpeg" alt="Image"
                         class="h-100 img-fluid card-img-hover"/>
                </div>
            </div>
            <div class="col-md-3 col-12">
                <div class="card card-hover shadow-lg rounded-4 h-100 p-2">
                    <img src="assets/img/landing-new/gallery/gallery4.jpeg" alt="Image"
                         class="h-100 img-fluid card-img-hover"/>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-5 pt-md-5">
    <div class="container">
        <p class="text-center fs-1 fw-bold primary-blue">Through Volmint's Vission</p>
        <div class="d-flex justify-content-center">
            <img src="assets/img/landing-new/gallery/gallery5.webp"/>
        </div>
    </div>
</div>

<div class="container-fluid mt-5 pt-md-5">
    <div class="container">
        <p class="text-center fs-1 fw-bold primary-blue">Our Culture</p>
        <p class="text-secondary text-center fs-6">We combine the power of technology and the creativity of Our
            Team to take creation to the next level.</p>
        <div class="row">
            <div class="col-12 col-md-6">
                <img src="assets/img/landing-new/gallery/gallery6.webp" alt="Image" class="img-fluid w-100"/>
            </div>
            <div class="col-12 col-md-6">
                <img src="assets/img/landing-new/gallery/gallery7.webp" alt="Image" class="img-fluid w-100"/>
            </div>
            <div class="col-12 mt-4">
                <img src="assets/img/landing-new/gallery/gallery8.webp" alt="Image" class="img-fluid w-100"/>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-5 py-5"
     style="background-image: url('/assets/img/landing-new/footer/footer-bg.png');">
    <div class="container">
        <div class="row text-center text-md-start">
            <div class="col-md-6 col-12 fs-1 color-white fw-bold mb-3 mb-md-0">
                Ready to take your Business’s IT to the next level?
            </div>
            <div class="col-md-6 col-12 text-md-end">
                <button class="bg-F5FCFF px-5 py-3 border-0 fs-6 fw-bold hover-effect" (click)="goTo('/contact')">
                    Contact Us <i class="fa fa-arrow-right" aria-hidden="true"></i>
                </button>
            </div>
        </div>
    </div>
</div>
