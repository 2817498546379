<!-- Start Page Title Area -->
<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <h2>Terms & Conditions</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Terms & Conditions</li>
            </ul>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start project Details Area -->
<div class="projects-details-area ptb-110">
    <div class="container">
        <div class="projects-details">
            <div class="projects-details-desc">
                <h3 style="text-align: center; color: #1b41b0;">Terms & Conditions</h3>
                <p>Welcome to <b>Volmint Technologies</b> available on the link <span class="color-primary">https://volmint.com/</span>
                    . <b>“Website</b>,<b>Us</b>,
                    <b>we</b> are
                    used to refer to Volmint Technologies, and “<b>You</b>, <b>Users</b> or <b>Visitors</b>” are used to refer to an
                    individual, legal entity, or company that accesses or runs our website.

                    We highly encourage visitors and users to read our terms and conditions carefully to always be
                    updated and informed when using our website or pages. The terms and conditions may update or modify
                    within a particular period or when needed. This may help the visitors to avoid any misconceptions
                    about the website and pages.

                    We would like to inform you that to continue or browse our website, you are bound and agreeing to
                    comply with the terms and conditions mentioned on the page.
                    .</p>
                <h5>Website Use</h5>
                <p>
                    This is to inform the visitors that the information, Content, material, or pages appearing on the
                    website is free of charge and for general information only. However, the information present on the
                    site is best of our knowledge and is subject to change without any prior notice to the visitors.
                    Neither we nor any other third parties claimed a guarantee or warranty as to the accuracy or
                    sustainability of the material and information available. Misuse, unauthorized use, sale, or fraud
                    of any information/ content or material available on the website is strictly prohibited and Volmint
                    Technologies reserved the right to take any legal action against the culprit.
                </p>
                <h5>Information Privacy</h5>
                <p>The visitors or users shall use the personal information appearing on the website by the Privacy
                    Policy or T&C described by Volmint Technologies. Use of the material available on the site without
                    permission would be considered a violation of our T&C..
                </p>
                <h5>Trademark, Copyright, and Laws</h5>
                <p>By aggreging to the terms and conditions, visitors or users acknowledge that the material available
                    on the website, including, without limitations, graphics, designs, formatting, text, sounds or
                    music, images, headers or footers, icons, placement, script, software, logo, service mark,
                    trademark, trade dress, material, link, information and especially the content is licensed and owned
                    by or to Volmint Technologies. The ownership is protected under the Indian Laws of international
                    copyright, trademark, and various other intellectual property rights. Apart from the authorization,
                    the visitors or user may not copy the content, republish, download, display, post, transmit,
                    exploit, reproduce or distribute the information without prior written authorization or permission
                    from Volmint Technologies.
                </p>
                <h5>Prohibited or Unlawful Activity</h5>
                <p>
                    We are highly against the use of the website for any illegal purpose, or unlawful, void, or abusive
                    content. Thus, we are requesting the users not to send any defamatory or obscene material as per the
                    applicable laws and Acts under the Indian Penal Court. </p>
                <h5>Violation </h5>
                <p>Violation of any terms or conditions described by or to Volmint Technologies intentionally or
                    unintentionally is strictly prohibited. Volmint Technologies reserved the right to take legal action
                    against the violator as per the applicable laws.
                </p>
                <p>For any queries, questions, or objections related to our Terms & Conditions, please let us know.</p>
                <p>Contact :</p>
                <ul>
                    <li><p> 29/2, Vijay Enclave, Dwarka-Palam Road, Delhi-110045
                    </p></li>
                    <li><p> Mobile: +91 9958029883
                    </p></li>
                    <li><p> E-mail: support@volmint.com
                    </p></li>
                </ul>
            </div>
        </div>
    </div>
</div>
<!-- End project Details Area -->
