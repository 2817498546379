<!-- Start Feedback Area -->
<section class="feedback-area">
    <div class="feedback-slides owl-carousel owl-theme">
        <!--<div class="row m-0">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="feedback-item">
                    <a href="https://www.educationmitra.in/" target="_blank">
                        <p style="text-align: center;color: black;">Case study #1</p>
                        <p class="text-center">World class University Management System</p>
                        <p>The workforce is undergoing a massive transformation. Employees are now working remotely due
                            to
                            COVID-19 pandemic. The gig economy is here to stay. Companies are looking at ways to keep
                            their
                            workers productive even if they do not physically interact with them. Therefore, HR
                            management
                            software should be able to adapt to these changes.<br><br><br><br></p>
                    </a>
                    <a href="https://www.educationmitra.in/" target="_blank">
                        <div class="client-info">
                            <div class="client-pic">
                                <img src="assets/img/case_study_v01.png" alt="image">
                            </div>
                            &lt;!&ndash; <h3>Filip Luis</h3>
                             <span>Founder & CEO</span>&ndash;&gt;
                        </div>
                    </a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12 p-0">
                <div class="client-image bg1">
                    <img src="assets/img/case_study_v01.png" alt="image">
                </div>
            </div>
        </div>-->
        <div class="row m-0">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="feedback-item">
                    <!--    <a href="https://www.educationmitra.in/" target="_blank">-->
                    <p style="text-align: center;color: black;">Case study #1</p>
                    <p class="text-center">Examination platform</p>
                    <p>Volmint Technologies is a cloud-based digital solutions provider offering
                        examination platform software with key features such as rating scale
                        questions, video questions, multiple choice questions, matrix questions,
                        dropdown questions, coding simulators, open-ended questions, true/false
                        questions, and many more. It provides services such as configuring online
                        exams, online invigilation, automatic grading, etc.<br><br><br></p>
                    <!-- </a>-->
                    <!--<a href="https://www.educationmitra.in/" target="_blank">-->
                    <div class="client-info">
                        <div class="client-pic">
                            <img src="assets/img/case_study_v01.webp" alt="image">
                        </div>
                        <!-- <h3>Filip Luis</h3>
                         <span>Founder & CEO</span>-->
                    </div>
                    <!-- </a>-->
                </div>
            </div>

            <div class="col-lg-6 col-md-12 p-0">
                <div class="client-image bg1">
                    <img src="assets/img/case_study_v01.webp" alt="image">
                </div>
            </div>
        </div>

        <!--<div class="row m-0">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="feedback-item">
                    <p style="text-align: center;color: black;">Case study #2 </p>
                    <p class="text-center">Highly advance E - Learning Platform</p>
                    <p>ERP System (Enterprise Resource Planning) system is a software application designed to help
                        companies manage their operations. An ERP system is often referred to as a computerized
                        management information system (CMIS). A CMIS helps companies track inventory, automate
                        accounting processes, streamline purchasing, and improve customer service. ERP Benefits The
                        benefits of an ERP system include increased productivity, reduced operating expenses, improved
                        customer satisfaction, and enhanced competitive advantage.</p>
                    <div class="client-info">
                        <div class="client-pic">
                            <img src="assets/img/case_study_v02.png" alt="image">
                        </div>
                        &lt;!&ndash; <h3>Sarah Taylor</h3>
                         <span>Designer</span>&ndash;&gt;
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12 p-0">
                <div class="client-image bg2">
                    <img src="assets/img/case_study_v02.png" alt="image">
                </div>
            </div>
        </div>-->
        <div class="row m-0">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="feedback-item">
                    <p style="text-align: center;color: black;">Case study #2 </p>
                    <p class="text-center">University Management System</p>
                    <p>Volmint Technologies is a cloud-based digital solutions provider offering
                        university management services to help automate tasks such as registering
                        students, managing fee records, admitting students, managing payrolls for
                        faculty members, and classroom training among others. The system ensures
                        transparency, reliability, accuracy, and integrity of data, information, and
                        records.<br><br><br></p>
                    <div class="client-info">
                        <div class="client-pic">
                            <img src="assets/img/case_study_v02.webp" alt="image">
                        </div>
                        <!-- <h3>Sarah Taylor</h3>
                         <span>Designer</span>-->
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 p-0">
                <div class="client-image bg2">
                    <img src="assets/img/case_study_v02.webp" alt="image">
                </div>
            </div>
        </div>

        <div class="row m-0">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="feedback-item">
                    <a href="https://www.educationmitra.in/" target="_blank">
                        <p style="text-align: center;color: black;">Case study #3 </p>
                        <p class="text-center">Education Mitra</p>
                        <p>Education Mitra is a cutting-edge search engine platform facilitating
                            admissions for numerous Open and Distance Learning/ Online
                            Learning Undergraduate/ Postgraduate programs. Volmint
                            Technologies is the cloud-based, multi-capability admission
                            management software helping the Education Mitra portal in
                            managing a wide array of organizational aspects such as admission
                            and fee payments to name a few. It simplifies the administration
                            process and assists in the smooth as well as the optimized running of
                            website operations.</p>
                    </a>
                    <div class="client-info">
                        <div class="client-pic">
                            <img src="assets/img/case-study/case_study_v04_educationmitra.webp" alt="image">
                        </div>
                        <!-- <h3>Sarah Taylor</h3>
                         <span>Designer</span>-->
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12 p-0">
                <div class="client-image bg5">
                    <img src="assets/img/case-study/case_study_v04_educationmitra.webp" alt="image">
                </div>
            </div>
        </div>
        <div class="row m-0">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="feedback-item">
                    <a href="https://www.edutantra.in/" target="_blank">
                        <p style="text-align: center;color: black;">Case study #4 </p>
                        <p class="text-center">Edutantra</p>
                        <p>Edutantra is an Ed-tech Company for professional and higher
                            education by providing career-relevant programs from prestigious
                            national and international universities. The ed-tech company relies
                            on Volmint Technologies for its Admission Customer Relationship
                            Management (CRM). The powerful, cloud-based management
                            software is specifically designed to help manage administrative
                            operations, data, and processes.<br><br><br></p>
                    </a>
                    <div class="client-info">
                        <div class="client-pic">
                            <img src="assets/img/case-study/case_study_v03_edutatantra.webp" alt="image">
                        </div>
                        <!-- <h3>Sarah Taylor</h3>
                         <span>Designer</span>-->
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 p-0">
                <div class="client-image bg3">
                    <img src="assets/img/case-study/case_study_v03_edutatantra.webp" alt="image">
                </div>
            </div>
        </div>

        <div class="row m-0">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="feedback-item">
                    <a href="https://www.draxico.com/" target="_blank">
                        <p style="text-align: center;color: black;">Case study #5 </p>
                        <p class="text-center">Dr. Axico</p>
                        <p>Dr. Axico is an online communication interface platform to have face-to-face
                            video calls with doctors to scientifically address health problems. This
                            eCommerce platform uses the services of Volmint technologies for website
                            management for a wide array of services such as appointment booking, billing
                            systems, sales&amp; purchases, and many more.<br><br><br><br></p>
                    </a>
                    <div class="client-info">
                        <div class="client-pic">
                            <img src="assets/img/case-study/case_study_v04_draxico.webp" alt="image">
                        </div>
                        <!-- <h3>Sarah Taylor</h3>
                         <span>Designer</span>-->
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12 p-0">
                <div class="client-image bg4">
                    <img src="assets/img/case-study/case_study_v04_draxico.webp" alt="image">
                </div>
            </div>
        </div>
        <!--  <div class="row m-0">
              <div class="col-lg-6 col-md-12 p-0">
                  <div class="feedback-item">
                      <p class="text-center">World class University Management System</p>
                      <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered
                          alteration in some form, by injected humor predefined words which don't look.</p>
                      <div class="client-info">
                          <div class="client-pic">
                              <img src="assets/img/client/client3.jpg" alt="image">
                          </div>
                          &lt;!&ndash;  <h3>Meg Lanning</h3>
                            <span>Support</span>&ndash;&gt;
                      </div>
                  </div>
              </div>

              <div class="col-lg-6 col-md-12 p-0">
                  <div class="client-image bg3">
                      <img src="assets/img/client/client3.jpg" alt="image">
                  </div>
              </div>
          </div>-->
    </div>
</section>
<!-- End Feedback Area -->
