<!-- Start Page Title Area -->
<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <h2>Volmint Disciplined Process</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Volmint Disciplined Process</li>
            </ul>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Area -->
<section class="services-area bg-f2f6f9 ptb-110">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="top-featured-card text-center bg-white rounded-4">
                    <div class="image-icon">
                        <img src="assets/img/home-six/featured/icon1.png" alt="icon">
                    </div>
                    <h3><a>Market Research</a></h3>
                    <p>Market research is the process of analyzing and understanding the needs, wants, and desires
                        of customers. It is a process that takes time and effort to complete.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="top-featured-card text-center bg-white rounded-4">
                    <div class="image-icon">
                        <img src="assets/img/home-six/featured/icon2.png" alt="icon">
                    </div>
                    <h3><a>Develop</a></h3>
                    <p>There are many different solutions for every problem, so it is important to find out what the
                        client is looking for in order to provide them with the best possible solution.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="top-featured-card text-center bg-white rounded-4">
                    <div class="image-icon">
                        <img src="assets/img/home-six/featured/icon3.png" alt="icon">
                    </div>
                    <h3><a>Design</a></h3>
                    <p>Designing a piece of design right software requires an understanding of what needs to be done,
                        how it should be done, and how to go about doing it.Design right software</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="top-featured-card text-center bg-white rounded-4">
                    <div class="image-icon">
                        <img src="assets/img/home-six/featured/maintain-01.png" style="width: 60px;height: 60px;"
                             alt="icon">
                    </div>
                    <h3><a>Maintain</a></h3>
                    <p>maintenance is the process of modifying a software system to keep it functioning at optimum
                        levels. It is an important part of the software life cycle.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="top-featured-card text-center bg-white rounded-4">
                    <div class="image-icon">
                        <img src="assets/img/home-six/featured/analytics-01.png" style="width: 60px;height: 60px;"
                             alt="icon">
                    </div>
                    <h3><a>Analytics</a></h3>
                    <p>Analytics of software after development is the process of analyzing the performance and usage of
                        a piece of software after it has been developed</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="top-featured-card text-center bg-white rounded-4">
                    <div class="image-icon">
                        <img src="assets/img/home-six/featured/icon4.png" alt="icon">
                    </div>
                    <h3><a>Technology</a></h3>
                    <p>We use latest Technology for designing of software and our model are robust and advanced.<br><br>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.svg" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="shape-img4"><img src="assets/img/shape/shape4.svg" alt="image"></div>
    <div class="shape-img5"><img src="assets/img/shape/shape5.svg" alt="image"></div>
    <div class="shape-img7"><img src="assets/img/shape/shape3.png" alt="image"></div>
    <div class="dot-shape1"><img src="assets/img/shape/dot1.png" alt="image"></div>
    <div class="dot-shape2"><img src="assets/img/shape/dot3.png" alt="image"></div>
    <div class="dot-shape4"><img src="assets/img/shape/dot4.png" alt="image"></div>
    <div class="dot-shape5"><img src="assets/img/shape/dot5.png" alt="image"></div>
    <div class="dot-shape6"><img src="assets/img/shape/dot6.png" alt="image"></div>
</section>
<!-- End Services Area -->
