import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-ad-gallery',
  templateUrl: './ad-gallery.component.html',
  styleUrls: ['./ad-gallery.component.scss']
})
export class AdGalleryComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

    goTo(url) {
        this.router.navigateByUrl(url);
    }

}
