import { Component, OnInit } from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {AlertService} from "../../../shared/services/alert.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

    constructor(
        public fb: FormBuilder,
        public alertService: AlertService,
        private router: Router) {
    }

  ngOnInit() {
  }

    goTo(url) {
        this.router.navigateByUrl(url);
    }


}
