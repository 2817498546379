import {Component, OnInit} from '@angular/core';
import {BlogService} from '../../../shared/services/blog.service';
import {Router} from "@angular/router";

@Component({
    selector: 'app-blog',
    templateUrl: './blog.component.html',
    styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
    blogs: any = [];
    cards = [
        {title: 'Winter Dressing Tips When It’s Really Cold Out', image: 'assets/img/landing-new/blogs/blog1.jpeg'},
        {title: 'Winter Dressing Tips When It’s Really Cold Out', image: 'assets/img/landing-new/blogs/blog2.jpg'},
        {title: 'Winter Dressing Tips When It’s Really Cold Out', image: 'assets/img/landing-new/blogs/blog3.jpg'},
        {title: 'Winter Dressing Tips When It’s Really Cold Out', image: 'assets/img/landing-new/blogs/blog4.jpg'},
        {title: 'Winter Dressing Tips When It’s Really Cold Out', image: 'assets/img/landing-new/blogs/blog5.jpg'},
        {title: 'Winter Dressing Tips When It’s Really Cold Out', image: 'assets/img/landing-new/blogs/blog6.jpg'},
        {title: 'Winter Dressing Tips When It’s Really Cold Out', image: 'assets/img/landing-new/blogs/blog7.jpg'},
        {title: 'Winter Dressing Tips When It’s Really Cold Out', image: 'assets/img/landing-new/blogs/blog8.jpg'},
        {title: 'Winter Dressing Tips When It’s Really Cold Out', image: 'assets/img/landing-new/blogs/blog6.jpg'},
        {title: 'Winter Dressing Tips When It’s Really Cold Out', image: 'assets/img/landing-new/blogs/blog7.jpg'},
        {title: 'Winter Dressing Tips When It’s Really Cold Out', image: 'assets/img/landing-new/blogs/blog8.jpg'},
    ];

    constructor(private blogData: BlogService, public router: Router) {
    }

    ngOnInit() {
        this.get();
    }

    get(params: any = {}) {
        this.blogs = [];
        this.blogData.get(params).subscribe(data => {
            console.log(data);
            if (data && Array.isArray(data.data)) {
                this.blogs = data.data;
            }
        });
    }

    goTo(url) {
        this.router.navigateByUrl(url);
    }
}
