import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {Observable} from 'rxjs';
import {AppUrl} from '../constants/app-url';
import {BaseService} from './base.service';

@Injectable({
    providedIn: 'root'
})
export class BlogService extends BaseService {

    constructor(public http: HttpClient,
                public alertService: AlertService,
                public globalService: GlobalService) {
        super(http, alertService, globalService);
    }

    get(data): Observable<any> {
        return this.getRequest(AppUrl.BLOG(), data);
    }

    detail(id, data): Observable<any> {
        return this.getRequest(AppUrl.BLOG_DEATILS(id), data);
    }

    getByTitle(title: any): Observable<any> {
        return this.getRequest(AppUrl.BLOGBYTITLE(title));
    }
}
