<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <p class="fs-5 color-white text-center">Services</p>
            <p class="fs-1 color-white text-center fw-bold">Tailored Solutions Designed For You</p>
            <div class="mt-5 text-center">
                <button class="bg-F5FCFF px-5 py-3 border-0 fs-6 fw-bold" (click)="goTo('/contact')">
                    Contact Us <i class="fa fa-arrow-right" aria-hidden="true"></i>
                </button>
            </div>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</div>

<div class="container-fluid mt-5 bg-light-pink py-5">
    <div class="container">
        <div class="row">
            <div class="col-12 text-center">
                <img src="assets/img/landing-new/services/services1.png" alt="Image" class="img-fluid"/>
            </div>
            <div class="col-12 text-center pt-4 mb-5">
                <p class="fs-1 fw-bold text-center">Your Vision, Our Technology: Driving Success Together!</p>
                <div class="row">
                    <div class="col-1"></div>
                    <div class="col-10">
                    <p class="text-secondary text-wrap fw-bold">Our expertise lies in bridging the gap between your business goals
                        and the latest technological advancements, ensuring that every project we undertake is a stepping
                        stone toward your future growth. Our services are customized to meet your specific requirements,
                        encompassing software development, IT consulting, and system integration, aiming to improve
                        efficiency and scalability.
                    </p>
                    </div>
                    <div class="col-1"></div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-5">
    <div class="container">
        <p class="fs-1 fw-bold text-center">How We Work</p>
        <p class="text-secondary text-wrap text-center fw-bold">At Volmint Technologies, our approach to delivering exceptional
            results is rooted in a meticulous process designed to meet your unique business needs.
        </p>
        <div class="row">
            <div class="col-md-6 col-12">
                <img src="assets/img/landing-new/services/services2.png" alt="Image" class="img-fluid"/>
            </div>
            <div class="col-md-6 col-12">
                <div class="row mt-5">
                    <div class="col-6">
                        <div class="row">
                            <div class="col-2 text-end">
                                <i class="fas fa-check-circle primary-blue bg-white fs-6 rounded-5"></i>
                            </div>
                            <div class="col-10 fw-bold text-secondary">
                                Understanding Your Vision
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="row">
                            <div class="col-2 text-end">
                                <i class="fas fa-check-circle primary-blue bg-white fs-6 rounded-5"></i>
                            </div>
                            <div class="col-10 fw-bold text-secondary">
                                In-Depth Analysis
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-6">
                        <div class="row">
                            <div class="col-2 text-end">
                                <i class="fas fa-check-circle primary-blue bg-white fs-6 rounded-5"></i>
                            </div>
                            <div class="col-10 fw-bold text-secondary">
                                Tailored Strategy Development
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="row">
                            <div class="col-2 text-end">
                                <i class="fas fa-check-circle primary-blue bg-white fs-6 rounded-5"></i>
                            </div>
                            <div class="col-10 fw-bold text-secondary">
                                Collaborative Design
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-6">
                        <div class="row">
                            <div class="col-2 text-end">
                                <i class="fas fa-check-circle primary-blue bg-white fs-6 rounded-5"></i>
                            </div>
                            <div class="col-10 fw-bold text-secondary">
                                Agile Implementation
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="row">
                            <div class="col-2 text-end">
                                <i class="fas fa-check-circle primary-blue bg-white fs-6 rounded-5"></i>
                            </div>
                            <div class="col-10 fw-bold text-secondary">
                                Seamless Integration
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-6">
                        <div class="row">
                            <div class="col-2 text-end">
                                <i class="fas fa-check-circle primary-blue bg-white fs-6 rounded-5"></i>
                            </div>
                            <div class="col-10 fw-bold text-secondary">
                                Ongoing Support
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="row">
                            <div class="col-2 text-end">
                                <i class="fas fa-check-circle primary-blue bg-white fs-6 rounded-5"></i>
                            </div>
                            <div class="col-10 fw-bold text-secondary">
                                Continuous Improvement
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-5 pt-md-4">
    <div class="container">
        <div class="row mt-5">
            <div class="col-md-6 col-12">
                <img src="assets/img/landing-new/services/services6.png" alt="Image" class="img-fluid"/>
            </div>
            <div class="col-md-6 col-12">
                <img src="assets/img/landing-new/services/services8.png" alt="Image" class="img-fluid"/>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-12 d-flex justify-content-center">
                <img src="assets/img/landing-new/services/services9.png" alt="Image" class="img-fluid"/>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mt-5 py-5" style="background-image: url('/assets/img/landing-new/footer/footer-bg.png');">
    <div class="container">
        <div class="row text-center text-md-start">
            <div class="col-md-8 col-12 fs-1 color-white fw-bold mb-3 mb-md-0">
                Ready to take your Business’ IT to the next level?
            </div>
            <div class="col-md-4 col-12 text-md-end">
                <button class="bg-F5FCFF px-5 py-3 border-0 fs-6 fw-bold" (click)="goTo('/contact')">
                    Contact Us <i class="fa fa-arrow-right" aria-hidden="true"></i>
                </button>
            </div>
        </div>
    </div>
</div>
